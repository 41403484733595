import React, { useState, useEffect, useRef } from "react";
import {
  Col,
  Container,
  Row,
  Table,
  Button,
  Modal,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ThreeDotSpinner } from "../loader";
import Pagination from "react-js-pagination";
import swal from "sweetalert";
import { apiService } from "../../service/api.service";
import moment from "moment";
import { baseUrl, dateFormate, formatBalance , formatWithDecimals} from "../../config/config";
import "bootstrap/dist/css/bootstrap.min.css";

export const UsersList = () => {
  const tooltip = <Tooltip id="tooltip">View</Tooltip>;
  const [userData, setUserData] = useState([]);
  const [totalItems, setTotalItems] = useState();
  const [loader, setLoader] = useState(false);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [type, setType] = useState("");
  const [deactivatedReason, setDeactivatedReason] = useState("");
  const [reasonError, setReasonError] = useState("");

  const [calldata, setcallData] = useState();
  const [limit, setlimit] = useState(10);
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };
  const [copied, setcopied] = useState(false);

  useEffect(() => {
    get_users(page, search);
  }, []);
  const tooltiptoggle = (
    <Tooltip id="tooltip">
      <p className="m-0 p-0">Change Status</p>
    </Tooltip>
  );
  const setcopytext = () => {
    setTimeout(() => {
      setcopied(false);
    }, "1000");
  };
  const copytooltip = (
    <Tooltip id="tooltip">{copied ? "Copied" : "Copy"}</Tooltip>
  );
  
  async function get_users(page, search) {
    setLoader(true);
    try {
      const response = await apiService.get_users(page, search);
      if (response?.status == 200) {
        let responseData = response.data.data.list;
        setUserData(responseData);
        setTotalItems(response?.data?.data.totalRecords);

        setLoader(false);
      }
    } catch (error) {
      if (error?.response?.status == 401) {
        setLoader(false);

        swal({ icon: "error", text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear();
          window.location.href = "/";
          console.log("OK button clicked after error alert");
        });
      } else {
        setLoader(false);

        swal({
          icon: "error",
          text: error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message,
          button: "OK",
        }).then((value) => {
          console.log("OK button clicked after error alert");
        });
      }
    }
  }

  function handlePageChange(pageNumber) {
    console.log("??????page", pageNumber);
    if (page != pageNumber - 1) {
      setPage(pageNumber - 1);
      get_users(pageNumber - 1, search);
    }
  }

  function handleUpdateSearch(search) {
    setSearch(search);
    setPage(0);
  }

  function disableUser() {
    if (deactivatedReason !== "") {
      console.log("??????????????saveeeebutton click", deactivatedReason);
      setReasonError("");
      setShow(false);
    } else {
      setReasonError("Please enter the reason");
    }
  }

  const generateCSVBet = async () => {
    setLoader(true);
    try {
      const response = await apiService.generateCSVBet();
      if (response?.status == 200) {
        setLoader(false);
        window.open(response.data.data.downloadurl, "_blank", "noreferrer");
        // console.log(response.data.data.downloadurl)
      }
    } catch (error) {
      if (error?.response?.status == 401) {
        setLoader(false);

        swal({ icon: "error", text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear();
          window.location.href = "/";
          console.log("OK button clicked after error alert");
        });
      } else {
        setLoader(false);

        swal({
          icon: "error",
          text: error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message,
          button: "OK",
        }).then((value) => {
          console.log("OK button clicked after error alert");
        });
      }
    }
  };

  const nftseedDatabase = async () => {
    setLoader(true);
    try {
      const response = await apiService.nftseedDatabase();
      if (response?.status == 200) {
        setLoader(false);
       swal("Success","Data added successfully","success")
        // console.log(response.data.data.downloadurl)
      }
    } catch (error) {
      if (error?.response?.status == 401) {
        setLoader(false);

        swal({ icon: "error", text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear();
          window.location.href = "/";
          console.log("OK button clicked after error alert");
        });
      } else {
        setLoader(false);

        swal({
          icon: "error",
          text: error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message,
          button: "OK",
        }).then((value) => {
          console.log("OK button clicked after error alert");
        });
      }
    }
  };
  const formatAddress = (addr) => {
    return `${addr.substring(0, 4)}...${addr.substring(addr.length - 4)}`;
  };

  return (
    <>
      <div className="dashboard-main-area">
        {loader == true ? <ThreeDotSpinner /> : ""}

        <Container fluid>
          <div className="dashboard-area-heading management-heading">
            <Row className="row justify-content-center align-items-center mb-3">
              <Col md={12} lg={11}>
                <div className="heading-top-area">
                  <div className="d-flex ">
                    <h2 className="align-self-center m-0">Users</h2>
                  </div>
                  <div className="heading-top-area-right">
                 
                    <div className="search-area">
                      <label>Search</label>
                      <form
                        onSubmit={(event) => {
                          event.preventDefault();
                        }}
                      >
                        <input
                          type="text"
                          className="form-control inner-input"
                          onChange={(e) => handleUpdateSearch(e.target.value)}
                          onKeyDown={(e) => {
                            if (!e.target.value && e.code === "Space") {
                              e.preventDefault();
                            }
                            if (e.key === "Enter") {
                              get_users(page, search, type);
                            }
                          }}
                        />
                        <i
                          className="fa fa-search"
                          aria-hidden="true"
                          onClick={() => get_users(page, search, type)}
                        ></i>
                        <i
                          className="fa fa-close d-none"
                          aria-hidden="true"
                        ></i>
                      </form>
                    </div>
                    <Button type="buttton" variant="unset" onClick={generateCSVBet}>Export CSV</Button>
                    <Button type="buttton" variant="unset" onClick={nftseedDatabase}>Fetch Referral Data</Button>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="pb-4 justify-content-center">
              <Col md={12} lg={11}>
                <div className="audit-request-box">
                  <Table responsive="sm" className="UserListTable">
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th>Wallet Address</th>
                        <th>Total Prediction</th>
                        <th className="email-section">Amount Spent</th>
                        <th>Reward Amount</th>
                        <th>Created At</th>
                      </tr>
                    </thead>
                    <tbody>
                      {userData && userData?.length > 0 ? (
                        userData.map((data, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td
                                onClick={setcopytext}
                                style={{ cursor: "pointer" }}
                              >
                                <a
                                  href={
                                    "/transaction?wallet_address=" +
                                    data?.wallet_address
                                  }
                                >
                                  {data?.wallet_address ? (
                                    <>{formatAddress(data?.wallet_address)}</>
                                  ) : (
                                    <>-</>
                                  )}
                                </a>{" "}
                                <OverlayTrigger
                                  placement="top"
                                  overlay={copytooltip}
                                >
                                  <CopyToClipboard
                                    text={data?.wallet_address}
                                    onCopy={() => setcopied(true)}
                                  >
                                    <i
                                      class="fa fa-clipboard"
                                      aria-hidden="true"
                                    ></i>
                                  </CopyToClipboard>
                                </OverlayTrigger>
                              </td>
                              <td>{data?.predictionCount}</td>
                              <td>{formatBalance(data?.totalAmount)}</td>
                              <td>{formatBalance(data?.totalRewardAmount)}</td>
                              <td>
                                {moment(data?.createdAt).format(dateFormate)}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr className="no-dat-found-box">
                          <td colSpan={10}>
                            <img
                              src={
                                require("../../assets/images/no-data.svg")
                                  .default
                              }
                              alt="da"
                            />
                            <h3 className="text-center text-light">
                              No data found
                            </h3>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  {userData && userData.length > 0 ? (
                    <Pagination
                      activePage={page + 1}
                      itemsCountPerPage={limit}
                      totalItemsCount={totalItems}
                      pageRangeDisplayed={5}
                      onChange={(e) => handlePageChange(e)}
                      prevPageText={"Prev"}
                      nextPageText={"Next"}
                    />
                  ) : null}
                </div>
              </Col>
            </Row>
          </div>
        </Container>

        <Modal show={show} onHide={handleClose} backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title>Call Key Points</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {calldata &&
              calldata.map((data, index) => {
                if (data.summary) {
                  const format1 = "DD-MM-YYYY HH:mm:ss";
                  var duration = moment.duration(
                    parseInt(data.call_duration),
                    "seconds"
                  );
                  console.log("====duration==", duration);
                  var formatted = duration.format("hh:mm:ss");
                  var date1 = new Date(data.call_time);
                  var dateTime1 = moment(date1).format(format1);
                  return (
                    <div className="keynote">
                      <p>
                        {" "}
                        Date&Time: <b>{dateTime1}</b>
                      </p>
                      <p className="">
                        {" "}
                        Duration: <b>{formatted}</b>
                      </p>
                      <p
                        dangerouslySetInnerHTML={{ __html: data.summary }}
                        className="call-key-text"
                      ></p>
                    </div>
                  );
                }
              })}
          </Modal.Body>
          <Modal.Footer className="pt-3">
            <Button
              variant="secondary"
              onClick={handleClose}
              className="close-btn"
            >
              Close
            </Button>
            <Button variant="primary" onClick={disableUser}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};
