import React, { useEffect, useState } from "react";
import {
    Container,
    Row,
    Col,
    Table,
    Button,
    Modal,
    Form,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";
import Pagination from "react-js-pagination";
import { ThreeDotSpinner } from "../loader";
import { apiService } from "../../service/api.service";
import swal from "sweetalert";
import { baseUrl , ITEMS_PER_PAGE } from "../../config/config";
import { useNavigate } from "react-router-dom";

let emptyData = {
    title: "",
    description: "",
    image: null,
    options: ["", ""]
}

export const GroupTemplates = () => {
    const [groupPredictionsList, setgroupPredictionsList] = useState([])
    const [loader, setLoader] = useState(false)
    const [totalItems, setTotalItems] = useState();
    const [page, setPage] = useState(0);
    const [modalVisible, setModalVisible] = useState(false)
    const [updategroupData, setUpdategroupData] = useState(emptyData)
    const [updategroupError, setUpdategroupError] = useState(emptyData)
    const history = useNavigate();


    useEffect(() => {
        onGetgroup(0)
    }, [])

    const onGetgroup = async (page) => {
        setLoader(true);
        try {
            const response = await apiService.getGroupTemplates(page + 1);
            if (response.status == 200) {
                setgroupPredictionsList(response.data.data)
                setTotalItems(response?.data?.pagination?.totalItems);
                setLoader(false);
            }
        } catch (error) {
            setLoader(false);
            if (error?.response?.status == 401) {
                swal({ text: "Unauthorized", button: "OK" }).then(() => {
                    localStorage.clear();
                    window.location.href = "/";
                });
            } else {
            }
        }
    }

    function handlePageChange(pageNumber) {
        if (page != pageNumber - 1) {
            setPage(pageNumber - 1);
            onGetgroup(pageNumber - 1)
        }
    }


    const handleError = (type, Message) => {
        setUpdategroupError(prevQuestionAnswer => {
            return {
                ...prevQuestionAnswer,
                [type]: Message
            };
        });
    }


    const onSelectImage = (e) => {
        const file = e.target.files[0];
        setUpdategroupData((prevState) => ({ ...prevState, image: file }));
        handleError("image", "")
    }

    function onChangeText(e) {
        let { name, value } = e.target;
        setUpdategroupData(prevQuestionAnswer => ({
            ...prevQuestionAnswer,
            [name]: value.trimStart()
        }));
        handleError([name], "")

    }

    const addOption = () => {
        setUpdategroupData((prevState) => ({
            ...prevState,
            options: [...prevState.options, ""],
        }));
        setUpdategroupError((prevState) => ({
            ...prevState,
            options: [...prevState.options, ""],
        }));
    };

    const updateOption = (index, value) => {
        const updatedOptions = [...updategroupData.options];
        updatedOptions[index] = value;

        setUpdategroupData((prevState) => ({
            ...prevState,
            options: updatedOptions,
        }));

        // Clear error if any
        const updatedErrors = [...updategroupError.options];
        updatedErrors[index] = "";

        setUpdategroupError((prevState) => ({
            ...prevState,
            options: updatedErrors,
        }));
    };

    const removeOption = (index) => {
        const updatedOptions = updategroupData.options.filter((_, idx) => idx !== index);
        setUpdategroupData((prevState) => ({
            ...prevState,
            options: updatedOptions,
        }));

        const updatedErrors = updategroupError.options.filter((_, idx) => idx !== index);
        setUpdategroupError((prevState) => ({
            ...prevState,
            options: updatedErrors,
        }));
    };


    const onCloseModal = () => {
        setModalVisible(false)
        setUpdategroupData(emptyData)
        setUpdategroupError(emptyData)
    }

    const onSave = () => {
        let valid = true;
        // if (!updategroupData.image) {
        //     handleError("image", "Image is required.")
        //     valid = false;
        // }
        if (!updategroupData.title.trim()) {
            handleError("title", "Title is required.")
            valid = false;
        }
        if (!updategroupData.description.trim()) {
            handleError("description", "Description is required.")
            valid = false;
        }
        updategroupData.options.forEach((option, index) => {
            if (!option.trim()) {
                setUpdategroupError(prevQuestionAnswer => {
                    const updatedAnswerOption = [...prevQuestionAnswer.options];
                    updatedAnswerOption[index] = 'Option is required.';
                    return {
                        ...prevQuestionAnswer,
                        options: updatedAnswerOption
                    };
                });
                valid = false;
            } else {
                console.log('Please select', updategroupData.options)
                let isUnique = updategroupData.options.filter(data => data.toLowerCase() === option.toLowerCase()).length > 1;
                if (isUnique) {
                    setUpdategroupError(prevQuestionAnswer => {
                        const updatedAnswerOption = [...prevQuestionAnswer.options];
                        updatedAnswerOption[index] = 'Options must be unique.';
                        return {
                            ...prevQuestionAnswer,
                            options: updatedAnswerOption
                        };
                    });
                    valid = false;
                }
            }
        });

        if (!valid) {
            console.log("Not a valid Data:::::::");
            return;
        } else {
            console.log("Valid Data::::");
            let data = new FormData();
            data.append("title", updategroupData.title);
            data.append("description", updategroupData.description);
            data.append("options", JSON.stringify(updategroupData.options));
            // data.append("timesAdded", 1);
            if (updategroupData.image) {
                data.append("image", updategroupData.image);
            }
            if (updategroupData?._id) {
                onUpdategroup(data, updategroupData?._id)
            } else {
                onAddinggroup(data)
            }
        }
    };


    const onAddinggroup = async (data) => {
        setLoader(true);
        try {
            const response = await apiService.addgroupPredictions(data);
            if (response.status == 201) {
                onCloseModal();
                setPage(0)
                onGetgroup(0)
                swal("Success", response?.data?.message, "success").then(() => { });
            }
        } catch (error) {
            setLoader(false);
            if (error?.response?.status == 401) {
                swal({ text: "Unauthorized", button: "OK" }).then(() => {
                    localStorage.clear();
                    window.location.href = "/";
                });
            } else {
            }
        }
    }


    const onUpdategroup = async (data, id) => {
        setLoader(true);
        try {
            const response = await apiService.updateGroupTemplate(data, id);
            if (response.status == 200) {
                onCloseModal();
                setPage(0)
                onGetgroup(0)
                swal("Success", "Group templates details updated successfully.", "success").then(() => { });

            }
        } catch (error) {
            setLoader(false);
            if (error?.response?.status == 401) {
                swal({ text: "Unauthorized", button: "OK" }).then(() => {
                    localStorage.clear();
                    window.location.href = "/";
                });
            } else {
            }
        }
    }

    const onChangeStatus = async (id, status) => {
        let data = ({
            isDisabled: status
        })
        setLoader(true)
        try {
            const response = await apiService.changegroupStatus(data, id);
            if (response.status == 200) {
                setPage(0)
                onGetgroup(0)
            }
            setLoader(false)
        } catch (error) {
            setLoader(false);
            if (error?.response?.status == 401) {
                swal({ text: "Unauthorized", button: "OK" }).then(() => {
                    localStorage.clear();
                    window.location.href = "/";
                });
            }
        }
    }

    return (
        <>
            <div className="dashboard-main-area">
                {loader ? <ThreeDotSpinner /> : ""}
                <Container fluid>
                    <div className="dashboard-area-heading management-heading">
                        <Row className="row justify-content-center align-items-center mb-3">
                            <Col md={12} lg={11}>
                                <div className="heading-top-area">
                                    <div className="d-flex ">
                                        <h2 className="align-self-center m-0">Group Templates</h2>
                                    </div>

                                    {/* <div className="export-csv-btn add-btn-area me-2">
                                        <Button
                                            type="button"
                                            variant="unset"
                                            onClick={() => {
                                                setUpdategroupData(emptyData)
                                                setModalVisible(true)
                                            }}
                                        >
                                            <i class="fa fa-plus" aria-hidden="true"></i> Add
                                        </Button>
                                    </div> */}
                                </div>
                            </Col>

                        </Row>

                        <Row className="pb-4 justify-content-center">
                            <Col md={12} lg={11}>
                                <div className="availabilit-section">
                                    <Table responsive="sm" className="UserListTable">
                                        <thead>
                                            <tr>
                                                <th>Sr. No.</th>
                                                <th>Title</th>
                                                <th className="email-section">
                                                    Description
                                                </th>
                                                <th>Data provider</th>
                                                <th>Copies</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {groupPredictionsList && groupPredictionsList?.length > 0 ? (
                                                groupPredictionsList?.map((data, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{ (((page) * ITEMS_PER_PAGE) + (index + 1)) }</td>
                                                            <td className="question-area">
                                                                <a>
                                                                    {data?.title ? (
                                                                        <>
                                                                            {data?.title?.length > 60
                                                                                ? data?.title?.slice(0, 60) +
                                                                                "..."
                                                                                : data?.title}
                                                                        </>
                                                                    ) : (
                                                                        <>-</>
                                                                    )}
                                                                </a>
                                                            </td>
                                                            <td className="email-section">
                                                                {data?.description ? (
                                                                    <>
                                                                        {data?.description?.length > 60
                                                                            ? data?.description?.slice(0, 60) +
                                                                            "..."
                                                                            : data?.description}
                                                                    </>
                                                                ) : (
                                                                    <>-</>
                                                                )}
                                                            </td>
                                                            <td>
                                                                {data?.data_provider}
                                                            </td>



                                                            {/* <td> */}
                                                            {/* <div className="view-eye-area">
                                                                    <div
                                                                        className="d-flex align-items-center"
                                                                        onClick={() => onChangeStatus(data?._id, !data?.isDisabled)}
                                                                    >
                                                                        <div className="edit-pencil">
                                                                            <input type="checkbox" switch checked={!data?.isDisabled} />

                                                                        </div>
                                                                    </div>

                                                                </div> */}
                                                            {/* <div className="group-template-switch">
                                                                    <Form>
                                                                        <Form.Check // prettier-ignore
                                                                            type="switch"
                                                                            id="custom-switch"
                                                                            onClick={() => onChangeStatus(data?._id, !data?.isDisabled)}
                                                                            checked={!data?.isDisabled}
                                                                        />
                                                                    </Form>
                                                                </div> */}

                                                            {/* </td> */}

                                                            <td>
                                                                {data?.timesAdded}
                                                            </td>
                                                            <td>
                                                                <div className="view-eye-area">
                                                                    <div
                                                                        className="d-flex align-items-center"
                                                                        onClick={() => {
                                                                            setUpdategroupData(data)
                                                                            setModalVisible(true)
                                                                        }}
                                                                    >
                                                                        <OverlayTrigger
                                                                            placement="top"
                                                                            overlay={() => {
                                                                                return (
                                                                                    <Tooltip id="tooltip">
                                                                                        <p className="m-0 p-0">Edit</p>
                                                                                    </Tooltip>
                                                                                )
                                                                            }}
                                                                        >
                                                                            <div className="edit-pencil">
                                                                                <i
                                                                                    className="fa fa-pencil"
                                                                                    style={{ cursor: "pointer" }}
                                                                                ></i>
                                                                            </div>
                                                                        </OverlayTrigger>{" "}
                                                                    </div>
                                                                    <div className="d-flex align-items-center">
                                                                        <OverlayTrigger
                                                                            placement="top"
                                                                            overlay={() => {
                                                                                return (
                                                                                    <Tooltip id="tooltip">
                                                                                        <p className="m-0 p-0">View</p>
                                                                                    </Tooltip>
                                                                                )
                                                                            }}
                                                                        >
                                                                            <div className="edit-pencil">
                                                                                <i
                                                                                    class="fa fa-eye"
                                                                                    onClick={() => { history("/group-template-detail/" + data?._id) }
                                                                                    }
                                                                                    style={{ cursor: "pointer" }}
                                                                                    aria-hidden="true"
                                                                                ></i>
                                                                            </div>
                                                                        </OverlayTrigger>{" "}
                                                                    </div>
                                                                </div>
                                                            </td>

                                                        </tr>
                                                    );
                                                })
                                            ) : (
                                                <tr className="no-dat-found-box">
                                                    <td colSpan={10}>
                                                        <img
                                                            src={
                                                                require("../../assets/images/no-data.svg")
                                                                    .default
                                                            }
                                                            alt="da"
                                                        />
                                                        <h3 className="text-center text-light">
                                                            No data found
                                                        </h3>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </div>
                                {groupPredictionsList.length > 0 && totalItems > ITEMS_PER_PAGE ? (
                                    <Pagination
                                        activePage={page + 1}
                                        itemsCountPerPage={ITEMS_PER_PAGE}
                                        totalItemsCount={totalItems}
                                        pageRangeDisplayed={5}
                                        onChange={(e) => handlePageChange(e)}
                                        prevPageText={"Prev"}
                                        nextPageText={"Next"}
                                    />
                                ) : null}
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
            <Modal
                className="add-pediction-area"
                show={modalVisible}
                onHide={() => onCloseModal()}
                backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {updategroupData?._id ? "Update Group Templates" : "Add "}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="pb-3">
                    <Row md={12}>
                        <Form>
                            <Form.Group className="mb-2">
                                <Form.Label>Image</Form.Label>
                                <div className="common-image-area">
                                    {!updategroupData.image && (
                                        <div className="upload-image-area">
                                            <input type="file"
                                                onChange={(e) => onSelectImage(e)}
                                                accept="image/*"
                                            />
                                            <i class="fa fa-plus" aria-hidden="true"></i>{" "}
                                            {updategroupError.image &&
                                                <span className="text-danger">
                                                    {updategroupError.image}
                                                </span>
                                            }
                                        </div>
                                    )}
                                    {updategroupData.image && (
                                        <div className="upload-image-area-spacing">
                                            <div className="upload-image-area-right">
                                                <img
                                                    src={updategroupData.image?.name ? URL.createObjectURL(updategroupData.image) : baseUrl + updategroupData.image}
                                                    alt="iamge"
                                                    height={200}
                                                    width={200}
                                                />
                                                <i
                                                    class="fa fa-trash"
                                                    style={{ cursor: "pointer" }}
                                                    aria-hidden="true"
                                                    onClick={() => setUpdategroupData((prevState) => ({ ...prevState, image: "" }))}
                                                ></i>

                                            </div>

                                            <div className="choose-image-area">
                                                <button
                                                    type="button"
                                                    style={{ cursor: "pointer" }}
                                                    variant="unset"
                                                >
                                                    Change Image
                                                </button>
                                                <input type="file" onChange={(e) => onSelectImage(e)} accept="image/*" />
                                            </div>


                                        </div>
                                    )}
                                </div>
                            </Form.Group>
                            <Form.Group className="mb-2">
                                <Form.Label>
                                    Title <span>*</span>
                                </Form.Label>
                                <input
                                    maxLength={100}
                                    className="form-control "
                                    type="textarea"
                                    value={updategroupData.title}
                                    name="title"
                                    onChange={onChangeText}
                                />
                                {updategroupError.title &&
                                    <span className="text-danger">
                                        {updategroupError.title}
                                    </span>
                                }
                            </Form.Group>
                            <Form.Group className="mb-2">
                                <Form.Label>
                                    Description <span>*</span>
                                </Form.Label>
                                <textarea
                                    maxLength={1500}
                                    className="form-control "
                                    type="textarea"
                                    name="description"
                                    value={updategroupData.description}
                                    onChange={onChangeText}
                                />
                                {updategroupError.description &&
                                    <span className="text-danger">
                                        {updategroupError.description}
                                    </span>
                                }
                            </Form.Group>
                            {updategroupData.options.map((item, index) => (
                                <Form.Group className="mb-2 option-area" key={index}>
                                    <Form.Label>Option {index + 1} <span>*</span></Form.Label>
                                    <div className="third-option">
                                        <input
                                            className="form-control"
                                            type="text"
                                            value={item}
                                            onChange={(e) => updateOption(index, e.target.value)}
                                        />
                                        {updategroupData.options.length > 2 && (
                                            <i
                                                className="fa fa-trash"
                                                style={{ cursor: "pointer" }}
                                                aria-hidden="true"
                                                onClick={() => removeOption(index)}
                                            ></i>
                                        )}
                                    </div>
                                    {updategroupError.options[index] && (
                                        <span className="text-danger">{updategroupError.options[index]}</span>
                                    )}
                                </Form.Group>
                            ))}
                            {/* {updategroupData.options.length < 7 && ( */}
                                <p className="add-option">
                                    <i className="fa fa-plus" aria-hidden="true" onClick={addOption}></i>
                                </p>
                            {/* )} */}
                        </Form>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => onSave()}>Save</Button>
                    <Button onClick={() => onCloseModal()} className="close-btn-bottom">
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
