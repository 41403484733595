import {
    Col,
    Container,
    Dropdown,
    Form,
    OverlayTrigger,
    Row,
    Table,
    Tooltip,
} from "react-bootstrap";
import React, { useState, useEffect } from "react";
import moment from "moment";
import { ThreeDotSpinner } from "../loader";
import {
    dateFormate,
    formatWithDecimals,
    transactionurl,
} from "../../config/config";
import Pagination from "react-js-pagination";
import swal from "sweetalert";
import { apiService } from "../../service/api.service";
import Select from "react-select";


export const FailedTransactions = () => {
    const [page, setPage] = useState(0);
    const [transactions, settransactions] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [loader, setLoader] = useState(false);
    const [filterValue, setFilterValue] = useState('FAILED');

    const filterOptions = [
        { value: "", label: "All" },
        { value: "EXECUTABLE", label: "Executable" },
        { value: "FAILED", label: "Failed" },
        { value: "PENDING", label: "Pending" },
    ];

    const statusDropdown = [
        {
            name: "FAILED",
            id: "FAILED",
        },
        {
            name: "PENDING",
            id: "PENDING",
        },
    ];


    useEffect(() => {
        transaction_list(0, filterValue);
    }, []);


    async function transaction_list(page, filterType) {
        setLoader(true);
        try {
            if (filterType && filterType === "EXECUTABLE") {
                let params = {
                    page: page
                }
                const response = await apiService.get_executable_transations_list(params);
                if (response?.status == 200) {
                    let totalItems = response.data?.pagination?.totalItems ? response.data.pagination.totalItems : 0
                    let list = response.data?.data ? response.data.data : null
                    settransactions(list);
                    setTotalItems(totalItems);
                    setLoader(false);
                }
            }
            else {
                let params = {
                    page: page,
                    status: filterType ? filterType : ''
                }
                const response = await apiService.get_failed_transations_list(params);
                if (response?.status == 200) {
                    let totalItems = response.data?.pagination?.totalItems ? response.data.pagination.totalItems : 0
                    let list = response.data?.data ? response.data.data : null
                    settransactions(list);
                    setTotalItems(totalItems);
                    setLoader(false);
                }
            }
        } catch (error) {
            if (error?.response?.status == 401) {
                setLoader(false);
                swal({ icon: "error", text: "Unauthorized", button: "OK" }).then(() => {
                    localStorage.clear();
                    window.location.href = "/";
                    console.log("OK button clicked after error alert");
                });
            } else {
                setLoader(false);
                swal({
                    icon: "error",
                    text: error?.response?.data?.message
                        ? error?.response?.data?.message
                        : error?.message,
                    button: "OK",
                }).then(() => {
                    console.log("OK button clicked after error alert");
                });
            }
        }
    };


    function handlePageChange(pageNumber) {
        if (page != pageNumber - 1) {
            setPage(pageNumber - 1);
            transaction_list(pageNumber - 1, filterValue);
        }
    };


    function handleStatusConfimation(transactionId, status) {
        let actionType = status == "FAILED" ? "PENDING" : "FAILED"
        swal({
            title: 'Are you sure?',
            text: `Are you sure you want to change status ${status} to ${actionType}?`,
            icon: 'warning',
            buttons: ['Cancel', `Yes, ${actionType}`],
            dangerMode: true,
        }).then((confirmed) => {
            if (confirmed) {
                handleTransactionStaus(transactionId);
            }
        })
    };


    async function handleTransactionStaus(transactionId) {
        try {
            const response = await apiService.transaction_status_change(transactionId);
            if (response.status === 200) {
                setLoader(true);
                if (transactions?.length == 1) {
                    setPage(0)
                    transaction_list(0, filterValue);
                }
                else {
                    transaction_list(page, filterValue);
                }
            }
        }
        catch (error) {
            if (error?.response?.status == 401) {
                setLoader(false);
                swal({ icon: "error", text: "Unauthorized", button: "OK" }).then(() => {
                    localStorage.clear();
                    window.location.href = "/";
                    console.log("OK button clicked after error alert");
                });
            } else {
                setLoader(false);
                swal({
                    icon: "error",
                    text: error?.response?.data?.message
                        ? error?.response?.data?.message
                        : error?.message,
                    button: "OK",
                }).then(() => {
                    console.log("OK button clicked after error alert");
                });
            }
        }
    };


    function handleFilterChange(value) {
        setPage(0);
        transaction_list(0, value);
    };


    return (
        <div className="dashboard-main-area">
            {loader == true ? <ThreeDotSpinner /> : ""}
            <Container fluid>
                <div className="dashboard-area-heading management-heading">
                    <Row className="row justify-content-center align-items-center mb-3">
                        <Col md={12} lg={11}>
                            <div className="heading-top-area">
                                <div className="d-flex ">
                                    <h2 className="align-self-center m-0">Failed Transaction</h2>
                                </div>
                                <div className="heading-top-area-right">
                                    <div className="filter-top-area me-2">
                                        <Form.Group
                                            className=""
                                            controlId="exampleForm.ControlInput1"
                                        >
                                            <Form.Label className="mb-0">Filter:</Form.Label>
                                            <Select
                                                options={filterOptions}
                                                placeholder="All"
                                                onChange={(e) => (handleFilterChange(e.value), setFilterValue(e.value))}
                                            />
                                        </Form.Group>
                                    </div>

                                </div>
                            </div>
                        </Col>

                    </Row>
                    <Row className="pb-4 justify-content-center">
                        <Col md={12} lg={11}>
                            <div className="audit-request-box">
                                <Table responsive="sm" className="UserListTable">
                                    <thead>
                                        <tr>
                                            <th>Sr. No.</th>
                                            <th>Prediction Name</th>
                                            <th>Prediction Type</th>
                                            <th>Data Provider</th>
                                            <th>Start Date</th>
                                            <th>End Date</th>
                                            <th>Amount</th>
                                            <th>Status</th>
                                            <th>View Transaction</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {totalItems != null &&
                                            totalItems > 0 &&
                                            transactions &&
                                            transactions?.length > 0 ? (
                                            transactions.map((data, index) => {

                                                const serialNumber = page * 10 + index + 1;
                                                let predictionDetail = data.params && data.params.length > 0 ? data.params[0] : ''

                                                let predictionQuestion = predictionDetail?.question ? predictionDetail.question : ''
                                                let predictionName = predictionQuestion && predictionQuestion.length > 50 ? predictionQuestion.slice(0, 50) + "..." : predictionQuestion

                                                let predictionType = predictionDetail?.prediction_type ? predictionDetail?.prediction_type === "RISK-FREE" ? "Prize pool" : predictionDetail?.prediction_type : "N/A"

                                                return (
                                                    <tr key={index}>
                                                        <td>{serialNumber}</td>
                                                        <td>{predictionName ? predictionName : "N/A"}</td>
                                                        <td>
                                                            {predictionType}
                                                            {predictionDetail?.prediction_category !== "REGULAR" && (
                                                                <>
                                                                    {predictionDetail?.prediction_category === "POLL" && " ( POLL )"}
                                                                    {predictionDetail?.prediction_category === "QUIZ" && " ( QUIZ )"}
                                                                    <span className="capitalized">
                                                                        &nbsp; {predictionDetail?.solo_type}
                                                                    </span>

                                                                </>
                                                            )}
                                                        </td>
                                                        <td style={{ textTransform: 'capitalize' }}> {predictionDetail?.data_provider ? predictionDetail.data_provider : "N/A"}</td>
                                                        <td> {predictionDetail?.startDateTime ? moment(predictionDetail.startDateTime).format(dateFormate) : "N/A"}</td>
                                                        <td>{predictionDetail?.endDateTime ? moment(predictionDetail.endDateTime).format(dateFormate) : "N/A"}</td>
                                                        <td>{predictionDetail?.amount ? formatWithDecimals(predictionDetail?.amount, predictionDetail?.decimal) : "N/A"}</td>
                                                        <td>
                                                            {
                                                                data.status === "PENDING" ?
                                                                    <div className="failed-transation-status pending">
                                                                        PENDING
                                                                    </div>
                                                                    :
                                                                    <Dropdown>
                                                                        <Dropdown.Toggle
                                                                            variant={data.status == "FAILED" ? "danger" : "success"}
                                                                            id="dropdown-basic">
                                                                            {data.status == "FAILED" ? "FAILED" : "PENDING"}
                                                                        </Dropdown.Toggle>
                                                                        <Dropdown.Menu>
                                                                            {
                                                                                statusDropdown.map((item) => (
                                                                                    data.status !== item.id &&
                                                                                    <Dropdown.Item
                                                                                        onClick={() => handleStatusConfimation(data._id, data.status)}
                                                                                    >
                                                                                        {item.name}
                                                                                    </Dropdown.Item>
                                                                                ))
                                                                            }
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                            }
                                                        </td>
                                                        <td>
                                                            <a
                                                                href={transactionurl + data.transaction_hash}
                                                                target="_blank"
                                                            >
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={<Tooltip id="tooltip">View</Tooltip>}
                                                                >
                                                                    <i
                                                                        class="fa fa-eye"
                                                                        style={{ cursor: "pointer" }}
                                                                        aria-hidden="true"
                                                                    ></i>
                                                                </OverlayTrigger>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        )
                                            : (
                                                <tr className="no-dat-found-box">
                                                    <td colSpan={10}>
                                                        <img
                                                            src={
                                                                require("../../assets/images/no-data.svg")
                                                                    .default
                                                            }
                                                            alt="da"
                                                        />
                                                        <h3 className="text-center text-light">
                                                            No data found
                                                        </h3>
                                                    </td>
                                                </tr>
                                            )}
                                    </tbody>
                                </Table>

                                {totalItems && totalItems > 0 ? (
                                    <Pagination
                                        activePage={page + 1}
                                        itemsCountPerPage={10}
                                        totalItemsCount={totalItems}
                                        pageRangeDisplayed={5}
                                        onChange={(e) => handlePageChange(e)}
                                        prevPageText={"Prev"}
                                        nextPageText={"Next"}
                                    />
                                ) : null}
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    );
};
