import { createContext } from "react";
import swal from "sweetalert";



// ****************** Test configration start **************************

  export const apiUrl = "http://138.197.86.118:3003/api/v1"
  export const baseUrl = "http://138.197.86.118:3003/"
  export const tokenAddress = "0x5E6168E0B90Ec559CF0376c4BFDCa4a5689Ae943"; //testnet
  export const feedWalletAddress = "0x7c4db95D43854B3b1bE0ACD53e34146C9451c417";
 // ****************** Test configration ends ***************

// ****************** staging configration start **************************
// export const apiUrl = "https://betfolio-api.ecomempire.in/api/v1"
// export const baseUrl = "https://betfolio-api.ecomempire.in/"
// export const tokenAddress = "0x04BcEfE854Bcfee99d6921249db6F83090b5B2b8";  // staging usdc6
// export const feedWalletAddress = "0x7c4db95D43854B3b1bE0ACD53e34146C9451c417";
// ****************** staging configration ends ***************

// ****************** live configration start **************************
// export const apiUrl = "https://api.betfolio.co/api/v1";
// export const baseUrl = "https://api.betfolio.co/";
// export const tokenAddress = "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359";  // live
// export const feedWalletAddress = "0xd28c1433d5CbAab8C54Bae2C3F4886849abB4E2F";
// ****************** live configration ends ***************

export const MyContext = createContext();

export const fundWalletAddress = "0xc42F48a8E1C2721FA25fdfDBb6A7d5e256A63694";
export const USDC_DECIMALS = 6;
export const FOLIO_DECIMALS = 18;

export const Unauthorized = () => {
  swal({ icon: "error", text: "Unauthorized", button: "OK" }).then(() => {
    localStorage.clear();
    window.location.href = "/";
    console.log("OK button clicked after error alert");
  });
};
export const dateFormate = "DD/MM/YYYY hh:mm A";
export const formatBalance = (rawBalance) => {
  const balance = (rawBalance / 1000000).toFixed(2);
  return balance;
};


export const  formatNumber = (num) => {
  let formattedNumber = num.toFixed(6);
  // Remove trailing zeros and decimal point if all zeros
  formattedNumber = formattedNumber.replace(/\.?0*$/, '');
  return formattedNumber;
}


export const ITEMS_PER_PAGE = 10;

export const formatWithDecimals = (number, decimals) => {
  decimals = decimals ? decimals : 6
  const divider = 10 ** decimals;
  let balance =number / divider;
  return formatNumber(balance);
}

/* for folio update when we are avaliable */

export const formatWithDecimalsFolio = (number, decimals) => {
  decimals = decimals ? decimals : FOLIO_DECIMALS
  const divider = 10 ** decimals;
  let balance =number / divider;
  return formatNumber(balance);
}


  // export const transactionurl =  "https://mumbai.polygonscan.com/tx/"
export const transactionurl = "https://polygonscan.com/tx/";

export const thresholdTimeFram = [
  { value: 2, label: "2 Mins" },
  { value: 240, label: "4 Hours" },
  { value: 360, label: "6 Hours" },
  { value: 720, label: "12 Hours" },
  { value: 1440, label: "24 Hours" },
  { value: 2880, label: "48 Hours" },
  { value: 4320, label: "72 Hours" },
  { value: 10080, label: "1 Week" },
  { value: 20160, label: "2 Weeks" },
];

export const formatWalletAddress = (address) => {
  if (!address) return '';
  const firstPart = address.slice(0, 4);
  const lastPart = address.slice(-4);
  return `${firstPart}...${lastPart}`;
};
