import React, { useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Table,
  Button,
  Modal,
  Form,
  Tooltip,
  OverlayTrigger,
  Tabs,
  Tab,
} from "react-bootstrap";
import { useState } from "react";
import Pagination from "react-js-pagination";
import { ThreeDotSpinner } from "../loader";
import { apiService } from "../../service/api.service";
import { validateService } from "../../service/validation.service";
import swal from "sweetalert";
import moment from "moment";
import Select from "react-select";

import { useNavigate } from "react-router-dom";
import {
  baseUrl,
  dateFormate,
  formatBalance,
  formatWithDecimals,
  thresholdTimeFram,
  fundWalletAddress,
  tokenAddress
} from "../../config/config";
const { Web3 } = require("web3");
export const PredictionManagement = () => {
  let history = useNavigate();
  function PredictionDetail(id) {
    history("/prediction-detail/" + id);
  }
  const getBalance = async () => {
    const response = await apiService.getBalance();
    if (response.status == 200) {
      localStorage.setItem(
        "balance",
        parseFloat(response.data.data).toFixed(4)
      );
    }
  };
  const options = [
    { value: "", label: "All" },
    { value: "DAY", label: "1 Day" },
    { value: "WEEK", label: "1 Week" },
    { value: "MONTH", label: "1 Month" },
  ];
  const optionsPrediction = [
    { value: "", label: "All" },
    { value: "PAID", label: "Paid" },
    { value: "RISK-FREE", label: "Prize pool" },

  ];

  const accessSettingOptions = [
    { value: 1, label: "Default Setting" },
    { value: 2, label: "Open to All" },
    { value: 3, label: "Dual NFT Required" },
    { value: 4, label: "NFT and Token Required" },
    { value: 5, label: "Alternate NFT Required" },
    { value: 6, label: "Only Tokens Required" },
  ]

  const dataProviders = ["artemis", "coinglass", "coinmarketcap", "dappradar", "defillama", "intotheblock", "lunarcrush", "twitter", "uncategorized"];
  const KeyDownPhone = [" ", "`", "~", "!", "@", "#", "$", "^", "&", "*", "(", ")", "_", "=", "{", "}", "[", "]", "'", "|", ";", ":", '"', "?", "/", ",", "<", ">", "\\", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"]
  const KeyDown = [" ", "`", "~", "!", "@", "#", "$", "^", "&", "*", "(", ")", "_", "-", "=", "+", "{", "}", "[", "]", "'", "|", ";", ":", '"', "?", "/", ",", "<", ">", "\\", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"]

  const [loader, setLoader] = useState(false);
  const [addData, setaddData] = useState(false);
  const [endDate, setEndDate] = useState();
  const [startDate, setstartDate] = useState();
  const [startDateSend, setstartDateSend] = useState();
  const [type, setType] = useState(localStorage.getItem("Predictions") || "Upcoming");
  const [accessSetting, setAccessSetting] = useState("")
  const [notvalidComissionAddress, setnotvalidComissionAddress] = useState("");
  const [notvalidAddress, setnotvalidAddress] = useState("");
  const [specificWalletAddrerss, setspecificWalletAddrerss] = useState([""]);
  const commissionDataRef = useRef(null);
  const specificWalletsRef = useRef(null);
  const [isWalletBalance, setisWalletBalance] = useState(false)
  const formatAddress = (addr) => {
    return `${addr.substring(0, 4)}...${addr.substring(addr.length - 4)}`;
  };
  const [predictionFields, setpredictionFields] = useState({
    prediction_type: "PAID",
    calcuate_amount: "",
    decimal: "",
    question: "",
    description: "",
    startDateTime: "",
    endDateTime: "",
    outcome: "",
    duration: "DAY",
    threshold_time: "",
    commissionData: [{ walletAddress: fundWalletAddress, percentage: 100 }],
    specificWallets: [""],
    canBet: false,
    amount: "",
    chain_name: "",
    symbol: "",
    token_address: tokenAddress,
    chain_type: "",
    prediction_category: "REGULAR",
    answerOption: ["", ""],
    accessSetting: 1,
    nftContractAddress: "",
    accessSettingTokenAmount: "",
    accessSettingTokenAddress: "",
    disclaimer: "You need to mint a <a href='https://betfolio-risk-free-predictions.nfts2.me/' target='_blank' rel='noopener noreferrer' >BetFolio Group NFT </a> to bet on this prediction.",
    data_provider: "uncategorized"


  });
  const [predictionFieldsErr, setpredictionFieldsErr] = useState({
    prediction_type: "PAID",
    isValid: true,
    isAmount: true,
    question: "",
    description: "",
    start_time: "",
    end_time: "",
    outcome: "",
    duration: "",
    threshold_time: "",
    commissionData: [{ walletAddress: "", percentage: "" }],
    specificWallets: [""],
    canBet: false,
    amount: "",
    chain_name: "",
    symbol: "",
    token_address: "",
    chain_type: ""

  });

  useEffect(() => {
    if (commissionDataRef.current) {
      commissionDataRef.current.scrollTop =
        commissionDataRef.current.scrollHeight;
    }
  }, [predictionFields.commissionData?.length]);

  useEffect(() => {
    if (specificWalletsRef.current) {
      specificWalletsRef.current.scrollTop =
        specificWalletsRef.current.scrollHeight;
    }
  }, [predictionFields.specificWallets]);

  const handelCloseData = () => {
    setpredictionId();
    setaddData(false);
  };
  const [dataoptions, setdataoptios] = useState([]);
  const [totalItems, settotalItems] = useState();
  const [page, setPage] = useState(0);
  const [duration, setduration] = useState("");
  const [predictionId, setpredictionId] = useState();
  const [quesImage, setquesImage] = useState();
  const [quesImageshow, setquesImageshow] = useState();
  const [predictionFilter, setpredictionFilter] = useState("")
  const [isTokenAddressModified, setIsTokenAddressModified] = useState(false);
  const [answerOptionError, setAnswerOptionError] = useState([])



  function addAnswerOption() {
    let predictionFieldsTemp = { ...predictionFields }
    let temp = [...predictionFieldsTemp.answerOption];
    temp.push("")
    predictionFieldsTemp.answerOption = temp;
    console.log("predictionFieldsTemp", predictionFieldsTemp)
    setpredictionFields(predictionFieldsTemp);
  }


  function answerOptionValidation() {
    let temp = [...answerOptionError], status = false;
    predictionFields.answerOption.map((item, index) => {
      if (predictionFields.prediction_category != "REGULAR" && (!item || !item.trim())) {
        temp[index] = "Please enter your answer";
        status = true;
      }
    })
    setAnswerOptionError(temp);
    return status;

  }

  function removeAnswerOption(index) {
    let predictionFieldsTemp = { ...predictionFields }
    let temp = [...predictionFieldsTemp.answerOption];
    temp.splice(index, 1);
    predictionFieldsTemp.answerOption = temp;
    setpredictionFields(predictionFieldsTemp);
  }

  function accessSettingOptionsChanged(optionselected) {
    setpredictionFields((prevObj) => {
      return {
        ...prevObj,
        accessSetting: optionselected,
      };
    });

    setpredictionFieldsErr({ ...predictionFieldsErr, accessSettingTokenAddressError: false, nftContractAddressError: false });

  }


  // get Contact address Detail
  const getTokenDetailsWithContact = async (token_address) => {
    setpredictionFields({
      ...predictionFields,
      chain_name: "",
      symbol: "",
      decimal: ""
    })
    setLoader(true);

    await apiService.getTokenwithContactAddress(token_address)
      .then((res) => {
        if (res?.data?.responseCode === 200) {

          setpredictionFields({
            ...predictionFields,
            chain_name: res?.data?.data?.name,
            symbol: res?.data?.data?.symbol,
            decimal: res?.data?.data?.decimal
          })
          setIsTokenAddressModified(false);
          setpredictionFieldsErr((obj) => {
            return {
              ...obj,
              token_address: "",
              isValid: true
            };
          });
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setpredictionFieldsErr((obj) => {
          return {
            ...obj,
            token_address: error?.response?.data?.message,
            isValid: false
          };
        });
        setIsTokenAddressModified(false);
        setLoader(false);
      });
  };
  // getWalletbalance
  const getWalletBalance = async (walletAddress) => {
    setisWalletBalance(true)
    await apiService.checkbalanceInWallet(walletAddress)
      .then((res) => {
        if (res?.data?.responseCode === 200) {
          console.log("getWalletBalance", res)
          const convertToExponential = (prediction) => {
            const multiplier = 10 ** Number(prediction?.decimal);
            return parseFloat(prediction?.amount * multiplier);
          };
          const convertWeiamount = convertToExponential(predictionFields);
          setpredictionFields((prevObj) => {
            return {
              ...prevObj,
              calcuate_amount: convertWeiamount
            }
          })
          let balance = res?.data?.data;
          if (balance < parseFloat(convertWeiamount)) {
            setpredictionFieldsErr((obj) => {
              return {
                ...obj,
                amount: "You have insufficient funds and you can't create this prediction.",
                isAmount: false
              };
            });
          }
          else {
            setpredictionFieldsErr((obj) => {
              return {
                ...obj,
                amount: "",
                isAmount: true
              };
            });
          }
          setisWalletBalance(false)
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setpredictionFieldsErr((obj) => {
          return {
            ...obj,
            amount: error?.response?.data?.message,
            isAmount: false
          };
        });
        setLoader(false);
      });
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setpredictionFields((prevObj) => ({
      ...prevObj,
      token_address: value,
    }));
    setIsTokenAddressModified(true);
  };

  const handleBlur = (e) => {
    if (
      predictionFields.prediction_type === 'RISK-FREE' &&
      isTokenAddressModified &&
      predictionFields.token_address !== ''
    ) {
      getTokenDetailsWithContact(e.target.value);
    }
  };


  const tooltip = (
    <Tooltip id="tooltip">
      <p className="m-0 p-0">Edit</p>
    </Tooltip>
  );
  const tooltipview = (
    <Tooltip id="tooltip">
      <p className="m-0 p-0">View</p>
    </Tooltip>
  );
  const tooltiptoggle = (
    <Tooltip id="tooltip">
      <p className="m-0 p-0">Change Status</p>
    </Tooltip>
  );
  const tooltipinfo = (
    <Tooltip id="tooltip">
      The bet time will appear, adjusted by substracting from the threshold
      time.
    </Tooltip>
  );

  useEffect(() => {
    const selectedTab = localStorage?.getItem("Predictions") || "Upcoming";
    if (selectedTab) {
      setType(selectedTab);
      get_prediction(0, selectedTab, duration, predictionFilter);
    }
  }, []);

  function getmintime() {
    let minTime = new Date().toISOString().slice(0, -8);
    var d = new Date(minTime);
    d.setHours(d.getHours() + 1);
    return minTime;
  }

  const handlePredictionTypeChange = (type) => {
    setpredictionFields(prevState => ({
      ...prevState,
      prediction_type: type
    }));
  };


  function handlePredictionChange(prediction) {
    setpredictionFilter(prediction);
    console.log("prediction after loop", prediction)
    get_prediction(page, type, duration, prediction);
  }

  function handlePageChange(pageNumber) {
    if (page != pageNumber - 1) {
      setPage(pageNumber - 1);
      get_prediction(pageNumber - 1, type, duration, predictionFilter);
    }
  }
  function handleDurationChange(durations) {
    if (durations != duration) {
      setduration(durations);
      get_prediction(page, type, durations, predictionFilter);
    }
  }
  async function get_prediction(page, type, duration, predictionFilter) {
    setLoader(true);
    try {
      const response = await apiService.get_prediction(page, type, duration, predictionFilter);
      if (response.status == 200) {
        setdataoptios(response?.data?.data?.list);
        settotalItems(response?.data?.data?.totalRecords);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      if (error?.response?.status == 401) {
        swal({ text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear();
          window.location.href = "/";
        });
      } else {
      }
    }
  }
  async function pin_unpin_prediction(isPin,id) {
    setLoader(true);
    try {
      let params ={
        isPin:isPin
      }
      const response = await apiService.pin_unpin_prediction(params,id);
      if (response.status == 200) {
        get_prediction(page, type, duration, predictionFilter);
        setLoader(false);
        let message = ""
        // alert(isPin)
        if (isPin){
           message = "Prediction Pinned successfully"
        }else{
           message = "Prediction Unpinned successfully"
        }
        swal("Success",message,"success")
      }
    } catch (error) {
      setLoader(false);
      if (error?.response?.status == 401) {
        swal({ text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear();
          window.location.href = "/";
        });
      } else {
      }
    }
  }

  

  function convertToUtc(dateTime) {
    let conversionDate = new Date(new Date(dateTime))
      .toISOString()
      .slice(0, -5);
    let d2 = moment.utc(conversionDate).format(dateFormate);
    d2 = d2.toString();
    return d2;
    console.log("====ttt====", Math.floor(new Date(d2).getTime() / 1000));
  }
  function OnchangeFromtime(timefrom) {

    setpredictionFields((prevObj) => {
      return {
        ...prevObj,
        startDateTime: timefrom.toLocaleString(),
      };
    });
    setstartDateSend(new Date(timefrom).toISOString().slice(0, -5));
    let startdateutc = convertToUtc(timefrom);
    setstartDate(startdateutc);
    endDateDuration(predictionFields.duration, timefrom);
  }

  function endDateDuration(duration, timefrom) {
    if (duration == "DAY" && timefrom) {
      let fromdate = new Date(new Date(timefrom).getTime() + 1 * 86400000)
        .toISOString()
        .slice(0, -5);
      let dateFormat = dateFormate;
      setEndDate(moment.utc(fromdate).format(dateFormat));
      let fromdateLocal = moment(
        new Date(new Date(timefrom).getTime() + 1 * 86400000)
      ).format(dateFormate);
      setpredictionFields((prevObj) => {
        return {
          ...prevObj,
          endDateTime: fromdateLocal,
        };
      });
    } else if (duration == "WEEK" && timefrom) {
      var fromdate = new Date(new Date(timefrom).getTime() + 7 * 86400000)
        .toISOString()
        .slice(0, -5);
      setEndDate(moment.utc(fromdate).format(dateFormate));
      let fromdateLocal = moment(
        new Date(new Date(timefrom).getTime() + 7 * 86400000)
      ).format(dateFormate);
      setpredictionFields((prevObj) => {
        return {
          ...prevObj,
          endDateTime: fromdateLocal,
        };
      });
    } else if (duration == "MONTH" && timefrom) {
      var fromdate = new Date(new Date(timefrom).getMonth() + 1)
        .toISOString()
        .slice(0, -5);

      var endDate = new Date(timefrom);
      endDate = new Date(endDate.setMonth(endDate.getMonth() + 1))
        .toISOString()
        .slice(0, -5);
      var endDateLocal = new Date(timefrom);
      endDateLocal = moment(
        new Date(endDateLocal.setMonth(endDateLocal.getMonth() + 1))
      ).format(dateFormate);
      setEndDate(moment.utc(endDate).format(dateFormate));
      setpredictionFields((prevObj) => {
        return {
          ...prevObj,
          endDateTime: endDateLocal,
        };
      });
    }
  }
  function OnchangeTotime(timefrom, label) {
    setpredictionFields((prevObj) => {
      return {
        ...prevObj,
        endDateTime: timefrom.toLocaleString(),
      };
    });
  }
  function toISOLocal(d) {
    var z = (n) => ("0" + n).slice(-2);
    var zz = (n) => ("00" + n).slice(-3);
    var off = d.getTimezoneOffset();
    console.log("==off===", off);
    var sign = off > 0 ? "-" : "+";
    off = Math.abs(off);
    console.log("==off===", off);
    return (
      d.getFullYear() +
      "-" +
      z(d.getMonth() + 1) +
      "-" +
      z(d.getDate()) +
      "T" +
      z(d.getHours()) +
      ":" +
      z(d.getMinutes()) +
      ":" +
      z(d.getSeconds()) +
      "." +
      zz(d.getMilliseconds()) +
      sign +
      z((off / 60) | 0) +
      ":" +
      z(off % 60)
    );
  }
  let commissionPercentage = 0;
  predictionFields.commissionData?.map((item, index) => {
    commissionPercentage = commissionPercentage + Number(item.percentage);
  });

  function resetFields() {
    setquesImageshow();
    setquesImage();
    setstartDateSend();
    setstartDate();
    setEndDate();
    setnotvalidAddress("");
    setnotvalidComissionAddress("");
    setspecificWalletAddrerss([""]);
    setpredictionFields({
      prediction_type: "PAID",
      calcuate_amount: "",
      decimal: "",
      question: "",
      description: "",
      startDateTime: "",
      endDateTime: "",
      duration: "DAY",
      outcome: "",
      threshold_time: "",
      commissionData: [{ walletAddress: fundWalletAddress, percentage: 100 }],
      specificWallets: [""],
      canBet: false,
      amount: "",
      chain_type: "",
      prediction_category: "REGULAR",
      answerOption: ["", ""],
      accessSetting: 1,
      nftContractAddress: "",
      accessSettingTokenAmount: "",
      accessSettingTokenAddress: "",
      disclaimer: "You need to mint a <a href='https://betfolio-risk-free-predictions.nfts2.me/' target='_blank' rel='noopener noreferrer' >BetFolio Group NFT </a> to bet on this prediction.",
      data_provider: "uncategorized"
    });
    setAnswerOptionError([]);
    setpredictionFieldsErr({
      prediction_type: "PAID",
      isValid: true,
      isAmount: true,
      question: "",
      description: "",
      start_time: "",
      end_time: "",
      outcome: "",
      duration: "",
      threshold_time: "",
      commissionData: [{ walletAddress: "", percentage: "" }],
      specificWallets: [""],
      canBet: false,
      amount: "",
      chain_type: ""
    });
  }


  const handlePredictionTypeToggle = () => {
    const newType = predictionFields.prediction_type === "PAID" ? "RISK-FREE" : "PAID";
    console.log("newType", newType);
    setpredictionFields(prevState => ({
      ...prevState,
      prediction_type: newType,
      token_address: newType === "PAID" ? tokenAddress : "",
      chain_name: "",
      symbol: "",
      decimal: "",
      amount: "",
    }));



    setpredictionFieldsErr((obj) => {
      return {
        ...obj,
        token_address: "",
        isValid: true
      };
    });
  };


  const removeImage = () => {
    if (predictionId && quesImage) {
      setquesImage("");
    } else {
      setquesImage();
    }
    setquesImageshow();
  };
  function getDateFromUTC(dateString) {
    const date = dateString
      .split(" ")[0]
      .split("-")
      .map((number) => parseInt(number));
    const hours = dateString
      .split(" ")[1]
      .split(":")
      .map((num) => parseInt(num));

    const convertedDate = new Date(
      Date.UTC(date[2], date[1] - 1, date[0], hours[0], hours[1], hours[2])
    );
    return convertedDate;
  }
  async function editPrediction(id) {
    resetFields();
    setpredictionId(id);
    const response = await apiService.get_predictionDetail(id);
    if (response.status == 200) {
      console.log("=====>>", response.data.data);
      setpredictionFields({ ...response.data.data, answerOption: response.data.data.answer_option });
      if (response.data.data.image && response.data.data.image != "undefined") {
        setquesImageshow(baseUrl + response.data.data.image);
      }
      console.log(
        "response.data.data.startDateTime",
        moment.utc(new Date(response.data.data.startDateTime))
      );
      var fromdate = new Date(response.data.data.startDateTime)
        .toISOString()
        .slice(0, -5);
      let tmpDate = new Date(response.data.data.startDateTime).toISOString();
      let startdateutc = convertToUtc(
        new Date(response.data.data.startDateTime)
      );
      setstartDate(moment.utc(new Date(response.data.data.startDateTime)));
      setstartDateSend(moment.utc(new Date(response.data.data.startDateTime)));
      setquesImage(response.data.data.image);
      var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
      var todate = new Date(response.data.data.endDateTime)
        .toISOString()
        .slice(0, -5);
      var fromdate = new Date(response.data.data.startDateTime)
        .toISOString()
        .slice(0, -5);
      const dateString = fromdate;
      const localDate1 = new Date(dateString + "Z");
      console.log(localDate1);

      let startDateTime = new Date(response.data.data.startDateTime);
      let localStartDateTime = new Date(startDateTime);
      localStartDateTime = moment(localStartDateTime)
        .format("yyyy-MM-DDTHH:mm:ss")
        .toString();
      console.log(localStartDateTime);
      setstartDate(
        moment.utc(response.data.data.startDateTime).format(dateFormate)
      );
      todate = moment(response.data.data.endDateTime).format(dateFormate);
      let utctodate = moment
        .utc(response.data.data.endDateTime)
        .format(dateFormate);
      setEndDate(utctodate);
      setpredictionFields((prevObj) => {
        return {
          ...prevObj,
          commissionData: response.data.data.platformWalletAddress,
        };
      });
      setpredictionFields((prevObj) => {
        return {
          ...prevObj,
          specificWallets: response.data.data.whitelistWalletAddress,
        };
      });
      setspecificWalletAddrerss(response.data.data.whitelistWalletAddress);
      setpredictionFields((prevObj) => {
        return {
          ...prevObj,
          canBet: response.data.data.canBet,
        };
      });
      setpredictionFields((prevObj) => {
        return {
          ...prevObj,
          specificWallets: response.data.data.whitelistWalletAddress,
        };
      });
      setpredictionFields((prevObj) => {
        return {
          ...prevObj,
          startDateTime: localStartDateTime,
        };
      });

      setpredictionFields((prevObj) => {
        return {
          ...prevObj,
          endDateTime: todate,
        };
      });
      setpredictionFields((prevObj) => {
        return {
          ...prevObj,
          outcome: response.data.data.outcome ? "true" : "false",
        };
      });
      setaddData(true);
    }
  }
  const onChangeImage = (e) => {
    const data = e.target.files[0];
    setquesImage(data);
    setquesImageshow(URL.createObjectURL(data));
  };
  function timeConvert(n) {
    var num = n;
    var hours = num / 60;
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);

    if (rminutes == 0) {
      if (rhours > 1) {
        return rhours + " Hours ";
      } else {
        return rhours + " Hour ";
      }
    } else {
      if (rhours > 1) {
        return rhours + " Hours " + rminutes + " mins.";
      } else {
        return rhours + " Hour " + rminutes + " mins.";
      }
    }
  }
  async function toTimestamp(strDate) {
    const dt = moment(strDate).unix();
    return dt.toString();
  }

  async function validAddress() {
    let valid = true;
    predictionFields.commissionData.map((value, index) => {
      if (!Web3.utils.isAddress(value.walletAddress)) {
        console.log(formatAddress(value.walletAddress) + " Not valid Address");
        setnotvalidComissionAddress(
          formatAddress(value.walletAddress) + " Not valid Address"
        );
        valid = false;
      }
    });
    return valid;
  }
  async function validAddressSpecified() {
    let valid = true;
    if (predictionFields.canBet) {
      predictionFields.specificWallets?.map((value, index) => {
        if (!isValidAddress(value)) {
          setnotvalidAddress(formatAddress(value) + " Not valid Address");
          console.log(formatAddress(value) + " Not valid Address");
          valid = false;
        }
      });
    }
    return valid;
  }


  function checkValidation() {
    // nftContractAddress
    // nftContractAddressName
    const errors = {};
    if (predictionFields?.prediction_type == "RISK-FREE" && !predictionFields.disclaimer) {
      errors.disclaimer = "This field is required";
    }
    if (predictionFields?.prediction_type == "RISK-FREE" && (predictionFields?.accessSetting == 4 || predictionFields?.accessSetting == 6) && (!predictionFields.accessSettingTokenAddress || !predictionFields.accessSettingTokenAddress.trim())) {
      errors.accessSettingTokenAddress = "This field is required";
    }
    if (predictionFields?.prediction_type == "RISK-FREE" && (predictionFields?.accessSetting == 4 || predictionFields?.accessSetting == 6) && (!predictionFields.accessSettingTokenAmount || !predictionFields.accessSettingTokenAmount.trim())) {
      errors.accessSettingTokenAmount = "This field is required";
    }
    // if (predictionFields?.prediction_type == "RISK-FREE" && (predictionFields?.accessSetting == 4 || predictionFields?.accessSetting == 6) && (parseFloat(predictionFields?.accessSettingTokenAmount) > accessSettingTokenAmountRef.current)) {
    //   errors.accessSettingTokenAmount = "You have insufficient funds";
    //   errors.accessSettingTokenAmountError = true;
    //   // setpredictionFieldsErr({...predictionFieldsErr,accessSettingTokenAmountError:true})
    // }
    if (predictionFields?.prediction_type == "RISK-FREE" && (predictionFields?.accessSetting == 3 || predictionFields?.accessSetting == 5) && (!predictionFields.nftContractAddress || !predictionFields.nftContractAddress.trim())) {
      errors.nftContractAddress = "This field is required";
    }
    return errors;
  }

  async function addprediction() {
    console.log("addprediction>>>>>");
    if (!(predictionFieldsErr.isValid)) {
      return;
    }
    console.log("addprediction>>>>>1", predictionFieldsErr);

    if (!(predictionFieldsErr?.isAmount)) {
      return;
    }
    console.log("addprediction>>>>>2");
    if (isWalletBalance == true) {
      setpredictionFieldsErr((obj) => {
        return {
          ...obj,
          amount: "Please wait for a moment until your account balance is checking."
        }
      })
      return;
    }
    console.log("addprediction>>>>> 3");
    let valid = validateService.predictionValidate(

      { ...predictionFields, commissionPercentage },
      setpredictionFieldsErr,
      predictionFieldsErr, answerOptionValidation
    );

    console.log("addprediction>>>>>4");
    let checkAddressValid = validAddress();
    if (checkAddressValid == true) {
      setnotvalidComissionAddress("");
    }

    const accessSettingError = checkValidation();
    console.log("addprediction accessSettingError", accessSettingError);

    console.log("addprediction 4.1", Object.keys(accessSettingError).length, Object.keys(accessSettingError).length != 0, predictionFieldsErr?.accessSettingTokenAddressError, predictionFieldsErr?.nftContractAddressError, accessSettingError?.accessSettingTokenAmountError)

    if ((Object.keys(accessSettingError).length != 0) || (predictionFieldsErr?.accessSettingTokenAddressError) || (predictionFieldsErr?.nftContractAddressError) || (accessSettingError?.accessSettingTokenAmountError)) {
      setpredictionFieldsErr({ ...predictionFieldsErr, ...accessSettingError });
      console.log("addprediction returing from here>>>")
      return
    }

    console.log("addprediction>>>>>5");
    let checkAddressValidSpiciFy = validAddressSpecified();
    if (checkAddressValidSpiciFy == true) {
      setnotvalidAddress("");
    }
    console.log("addprediction>>>>>6");
    await checkAddressValid
      .then((result) => {
        checkAddressValid = String(result);
      })
      .catch((error) => {
        console.error("Error occurred:", error);
      });
    await checkAddressValidSpiciFy
      .then((result) => {
        checkAddressValidSpiciFy = String(result);
      })
      .catch((error) => {
        console.error("Error occurred:", error);
      });
    console.log(checkAddressValid == "true");
    console.log(checkAddressValidSpiciFy);
    if (
      valid &&
      checkAddressValid == "true" &&
      checkAddressValidSpiciFy == "true"
    ) {

      setnotvalidAddress("");
      setnotvalidComissionAddress("");
      setLoader(true);

      // if (predictionFields.prediction_category != "REGULAR" && new Set(Object.values(predictionFields.answerOption)).size !== Object.values(predictionFields.answerOption).length) {
      //   swal("Warning", "All fields must have unique data.", "warning")
      //   setLoader(false); // Stop loader
      //   return;
      // }
      if (
        predictionFields.prediction_category !== "REGULAR" &&
        new Set(Object.values(predictionFields.answerOption).map(value => value.toLowerCase())).size !== Object.values(predictionFields.answerOption).length
      ) {
        swal("Warning", "All fields must have unique data.", "warning");
        setLoader(false); // Stop loader
        return;
      }

      try {
        predictionFields.outcome = true;
        var response;
        if (predictionId) {
          response = await apiService.updateprediction(
            predictionFields,
            predictionId,
            quesImage,
            startDateSend
          );
        } else {
          if (predictionFields.prediction_type == "PAID") {
            predictionFields.decimal = 6;
            predictionFields.symbol = "USDC";
          }

          console.log("calcuate_amount apiService.addprediction predictionFields", predictionFields);

          response = await apiService.addprediction(
            predictionFields,
            quesImage,
            startDateSend
          );
        }
        console.log(response);
        if (response.status == 200) {
          get_prediction(page, type, duration, predictionFilter);
          setLoader(false);
          handelCloseData();
          swal("Success", response?.data?.message, "success").then(() => { });
        }
      } catch (error) {
        if (
          error?.response?.data?.message?.match(
            "Returned error: insufficient funds for gas"
          )
        ) {
          swal(
            "Error",
            "Insufficient funds for gas * price.Please add funds to your wallet",
            "error"
          );
          handelCloseData();
        }
        setLoader(false);
        if (error?.response?.status == 401) {
          swal({ text: "Unauthorized", button: "OK" }).then(() => {
            localStorage.clear();
            window.location.href = "/";
          });
        } else {
        }
      }
    }
  }
  function matchExact(r, str) {
    var match = str.match(r);
    return match && str === match[0];
  }
  function handleSelect(key) {
    if (type != key) {
      localStorage.setItem("Predictions", key)
      setType(key);
      setPage(0);
      console.log("key========", key)
      get_prediction(0, key, duration, predictionFilter);
    }
  }
  function parseMinutes(x) {
    let hours = Math.floor(x / 60);
    let minutes = x % 60;

    if (hours > 1) {
      if (minutes > 0) {
        return hours + " Hours" + " " + minutes + " min";
      } else {
      }
      return hours + " Hours";
    } else {
      if (hours == 1) {
        if (minutes > 0) {
          return hours + " Hour" + " " + minutes + " min";
        } else {
        }
        return hours + " Hour";
      } else {
        return minutes + " min";
      }
    }
  }
  function addDatapop() {
    getBalance();
    // getWalletBalance(tokenAddress)
    resetFields();
    setaddData(true);
  }

  // Function to handle changes in wallet address
  const handleWalletAddressChange = (index, value) => {
    const newData = [...predictionFields.commissionData];
    newData[index] = { ...newData[index], walletAddress: value };
    setpredictionFields({ ...predictionFields, commissionData: newData });
  };

  // Function to handle changes in percentage
  const handlePercentageChange = (index, value) => {
    const newData = [...predictionFields.commissionData];
    newData[index] = { ...newData[index], percentage: value };
    setpredictionFields({ ...predictionFields, commissionData: newData });
  };

  // Function to add a new commission row
  const handleAddCommissionRow = (i) => {
    if (isValidAddress(predictionFields.commissionData[i].walletAddress)) {
      setpredictionFields({
        ...predictionFields,
        commissionData: [
          ...predictionFields.commissionData,
          { walletAddress: "", percentage: "" },
        ],
      });
    } else {
      setnotvalidComissionAddress(
        formatAddress(predictionFields.commissionData[i].walletAddress) +
        " Not valid Address"
      );
    }
  };

  function isValidAddress(address) {
    try {
      return Web3.utils.isAddress(address);
    } catch (error) {
      swal("Error", address + " is not valid address please recheck", "error");
      return false;
    }
  }
  // Function to remove a commission row
  const handleRemoveCommissionRow = (index) => {
    const newData = [...predictionFields.commissionData];
    if (newData.length > 1) {
      newData.splice(index, 1);
      setpredictionFields({ ...predictionFields, commissionData: newData });
    }
  };

  const handleAddWallet = (i) => {
    if (i == 0) {
      setpredictionFields({
        ...predictionFields,
        specificWallets: [...predictionFields.specificWallets, ""],
      });
    } else {
      if (isValidAddress(predictionFields.specificWallets[i])) {
        setpredictionFields({
          ...predictionFields,
          specificWallets: [...predictionFields.specificWallets, ""],
        });
      } else {
        setnotvalidAddress(
          predictionFields.specificWallets[i] + " not valid Address"
        );
      }
    }
  };

  // Function to remove a specific wallet address
  const handleRemoveWallet = (index) => {
    const updatedWallets = [...predictionFields.specificWallets];
    updatedWallets.splice(index, 1);
    setpredictionFields({
      ...predictionFields,
      specificWallets: updatedWallets,
    });
  };

  // Function to handle changes in a specific wallet address
  const handleWalletChange = (index, value) => {
    const updatedWallets = [...predictionFields.specificWallets];
    updatedWallets[index] = value;
    setpredictionFields({
      ...predictionFields,
      specificWallets: updatedWallets,
    });
  };

  const accessSettingTokenAmountRef = useRef();

  const handleAccessSettingTokenBlur = async (e) => {
    try {
      const tokenAddress = e.target.value;
      if (!tokenAddress) {
        setpredictionFieldsErr({ ...predictionFieldsErr, accessSettingTokenAddress: "This field is required" })
        return
      }
      const isValidToken = isValidAddress(tokenAddress);
      if (isValidToken) {
        setLoader(true)

        try {
          let response = await apiService.getTokenwithContactAddress(tokenAddress);
          if (response.status === 200) {
            const { decimal, name, symbol } = response.data.data;

            response = await apiService.checkbalanceInWallet(tokenAddress);
            if (response.status === 200) {
              accessSettingTokenAmountRef.current = Number(response.data.data) / 10 ** Number(decimal);
              console.log("accessSettingTokenAmountRef", accessSettingTokenAmountRef.current);
              setpredictionFields({ ...predictionFields, accessSettingTokenName: name, accessSettingTokenSymbol: symbol, accessSettingTokenDecimal: decimal });
              setpredictionFieldsErr({ ...predictionFieldsErr, accessSettingTokenAddressError: false, accessSettingTokenAddress: "" })
              setLoader(false)
            }
          } else {
            console.log("error>>>>>>> not getting the token")
            accessSettingTokenAmountRef.current = null;
            setpredictionFieldsErr({ ...predictionFieldsErr, accessSettingTokenAddressError: true, accessSettingTokenAddress: "Token address is not valid please recheck" });
            setLoader(false)
          }

        }
        catch (err) {
          console.log("error>>>>>>> not getting the token", err)
          accessSettingTokenAmountRef.current = null;
          setpredictionFieldsErr({ ...predictionFieldsErr, accessSettingTokenAddressError: true, accessSettingTokenAddress: "Token address is not valid please recheck" });
          setLoader(false)
        }

      } else {
        accessSettingTokenAmountRef.current = null;
        setpredictionFieldsErr({ ...predictionFieldsErr, accessSettingTokenAddressError: true, accessSettingTokenAddress: "Token address is not valid please recheck" });
      }
    } catch (error) {
      console.error('Error fetching token balance:', error);
      setLoader(false)
      // Handle error
    }
  };

  const handlenftContractAddressBlur = async (e) => {
    try {
      const tokenAddress = e.target.value;
      setpredictionFields({ ...predictionFields, nftContractAddressName: "", nftContractAddressSymbol: "" });
      if (!tokenAddress) {
        setpredictionFieldsErr({ ...predictionFieldsErr, nftContractAddress: "This field is required" })
        return
      }
      const isValidToken = isValidAddress(tokenAddress);
      if (isValidToken) {
        let response = await apiService.getContactNameByAddress(tokenAddress);
        if (response.status === 200) {
          const { name, symbol } = response.data.data;
          setpredictionFields({ ...predictionFields, nftContractAddressName: name, nftContractAddressSymbol: symbol });
          setpredictionFieldsErr({ ...predictionFieldsErr, nftContractAddressError: false, nftContractAddress: "" })
        } else {
          // accessSettingTokenAmountRef.current = null;
          setpredictionFieldsErr({ ...predictionFieldsErr, nftContractAddressError: true, nftContractAddress: "NFT contract address is not valid please recheck" });
        }
      } else {
        setpredictionFieldsErr({ ...predictionFieldsErr, nftContractAddressError: true, nftContractAddress: "NFT contract address is not valid please recheck" });
      }
    } catch (error) {
      console.error('Error fetching token balance:', error);
      // accessSettingTokenAmountRef.current = null;
      setpredictionFieldsErr({ ...predictionFieldsErr, nftContractAddressError: true, nftContractAddress: "NFT contract address is not valid please recheck" });
    }
  };

  const handleAccessSettingTokenAmountChange = (e) => {
    const amount = e.target.value.trim();
    if (isNaN(amount)) {
      return;
    }
    console.log("amount", amount, accessSettingTokenAmountRef.current);
    setpredictionFields((prevFields) => ({
      ...prevFields,
      accessSettingTokenAmount: amount,
    }));
    setpredictionFieldsErr({ ...predictionFieldsErr, accessSettingTokenAmount: "" });
  };

  return (
    <>
      <div className="dashboard-main-area">
        {loader ? <ThreeDotSpinner /> : ""}
        <Container fluid>
          <div className="dashboard-area-heading management-heading">
            <Row className="row justify-content-center align-items-center mb-3">
              <Col md={12} lg={11}>
                <div className="heading-top-area">
                  <div className="d-flex ">
                    <h2 className="align-self-center m-0">Predictions</h2>
                  </div>
                  <div className="heading-top-area-right">
                    <div className="export-csv-btn add-btn-area me-2">
                      <Button
                        type="button"
                        variant="unset"
                        onClick={() => addDatapop()}
                      >
                        <i class="fa fa-plus" aria-hidden="true"></i> Add
                      </Button>
                    </div>
                    <div className="filter-top-area me-2">
                      <Form.Group
                        className=""
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="mb-0">Filter:</Form.Label>
                        <Select
                          options={options}
                          placeholder="All"
                          onChange={(e) => handleDurationChange(e.value)}
                        />
                      </Form.Group>
                    </div>
                    <div className="filter-top-area select-prediction me-2">
                      <Form.Group
                        className=""
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="mb-0">Type:</Form.Label>
                        <Select
                          options={optionsPrediction}
                          placeholder="All"
                          onChange={(e) => handlePredictionChange(e.value)}
                        />
                      </Form.Group>
                    </div>
                    {/* <div className="search-area">
                      <label>Search</label>
                      <form
                        onSubmit={(event) => {
                          event.preventDefault();
                        }}
                      >
                        <input
                          type="text"
                          className="form-control inner-input"/>
                        <i
                          className="fa fa-search"
                          aria-hidden="true" ></i>
                        <i
                          className="fa fa-close d-none"
                          aria-hidden="true"
                        ></i>
                      </form>
                    </div> */}
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="pb-4 justify-content-center">
              <Col md={12} lg={11}>
                <Tabs
                  defaultActiveKey="Upcoming"
                  id="uncontrolled-tab-example"
                  className="prediction-tab mb-3"
                  onSelect={handleSelect}
                  activeKey={type}

                >
                  <Tab eventKey="Upcoming" title="Upcoming Prediction">
                    <div className="availabilit-section">
                      <Table responsive="sm" className="UserListTable">
                        <thead>
                          <tr>
                            <th></th>
                            <th>Sr. No.</th>
                            <th>Statement Title</th>
                            <th className="email-section">
                              Prediction Start Date & Time(UTC)
                            </th>
                            <th>Prediction End Date & Time(UTC)</th>
                            <th>
                              Threshold{" "}
                              <OverlayTrigger
                                placement="top"
                                overlay={tooltipinfo}
                              >
                                <i
                                  class="fa fa-info-circle"
                                  aria-hidden="true"
                                ></i>
                              </OverlayTrigger>
                            </th>
                            <th>Prediction Type</th>

                            <th>Total Predictions</th>
                            <th>Amount Predicted</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {dataoptions && dataoptions?.length > 0 ? (
                            dataoptions?.map((data, index) => {
                              console.log("dataoptions", data)
                              return (
                                <tr key={index}>
                                      {data.solo_type.trim()==""?
                                  <td onClick={()=>pin_unpin_prediction(data.isPin?false:true,data._id)}><i className={data.isPin?"fa fa-thumb-tack errors":"fa fa-thumb-tack"} aria-hidden="true"></i></td>:<td></td>}
                                  <td>{index + 1}</td>
                                  <td className="question-area">
                                    <a
                                      href={
                                        "/transaction?contract=" +
                                        data?.contract_address?.toLowerCase()
                                      }
                                    >
                                      {data?.question ? (
                                        <>
                                          {data?.question?.length > 30
                                            ? data?.question?.slice(0, 30) +
                                            "..."
                                            : data?.question}
                                        </>
                                      ) : (
                                        <>-</>
                                      )}
                                    </a>
                                  </td>
                                  <td className="email-section">
                                    {data?.startDateTime ? (
                                      <>
                                        {moment
                                          .utc(data?.startDateTime)
                                          .format("DD-MM-YYYY hh:mm A")}
                                      </>
                                    ) : (
                                      <>-</>
                                    )}
                                  </td>
                                  <td>
                                    {data?.endDateTime ? (
                                      <>
                                        {moment
                                          .utc(data?.endDateTime)
                                          .format("DD-MM-YYYY hh:mm A")}
                                      </>
                                    ) : (
                                      <>-</>
                                    )}
                                  </td>

                                  <td>
                                    {data?.threshold_time ? (
                                      <>{parseMinutes(data.threshold_time)}</>
                                    ) : (
                                      <>-</>
                                    )}
                                  </td>
                                  <td>
                                    {data?.prediction_type ? data?.prediction_type === "RISK-FREE" ? "Prize pool" : data?.prediction_type : ""}
                                    {data?.prediction_category !== "REGULAR" && (
                                      <>
                                        {data?.prediction_category === "POLL" && " ( POLL )"}
                                        {data?.prediction_category === "QUIZ" && " ( QUIZ )"}
                                        <span className="capitalized">
                                          &nbsp; {data?.solo_type}
                                        </span> 
                                        
                                      </>
                                      
                                    )}
                                    
                                  </td>

                                  <td>{data?.predictionCount}</td>
                                  <td>
                                    {formatWithDecimals(data?.totalAmount, data?.decimal)} {data?.symbol}
                                  </td>
                                  <td>
                                    <div className="view-eye-area">
                                      <div
                                        className="d-flex align-items-center"
                                        onClick={() =>
                                          editPrediction(
                                            data?.contract_address?.toLowerCase()
                                          )
                                        }
                                      >
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={tooltip}
                                        >
                                          <div className="edit-pencil">
                                            <i
                                              className="fa fa-pencil"
                                              style={{ cursor: "pointer" }}
                                            ></i>
                                          </div>
                                        </OverlayTrigger>{" "}
                                      </div>
                                      <div className="d-flex align-items-center">
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={tooltipview}
                                        >
                                          <div className="edit-pencil">
                                            <i
                                              class="fa fa-eye"
                                              onClick={() =>
                                                PredictionDetail(
                                                  data.contract_address.toLowerCase()
                                                )
                                              }
                                              style={{ cursor: "pointer" }}
                                              aria-hidden="true"
                                            ></i>
                                          </div>
                                        </OverlayTrigger>{" "}
                                      </div>
                                    </div>
                                  </td>

                                </tr>
                              );
                            })
                          ) : (
                            <tr className="no-dat-found-box">
                              <td colSpan={10}>
                                <img
                                  src={
                                    require("../../assets/images/no-data.svg")
                                      .default
                                  }
                                  alt="da"
                                />
                                <h3 className="text-center text-light">
                                  No data found
                                </h3>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                    {dataoptions.length > 0 && totalItems > 10 ? (
                      <Pagination
                        activePage={page + 1}
                        itemsCountPerPage={10}
                        totalItemsCount={totalItems}
                        pageRangeDisplayed={5}
                        onChange={(e) => handlePageChange(e)}
                        prevPageText={"Prev"}
                        nextPageText={"Next"}
                      />
                    ) : null}
                  </Tab>
                  <Tab eventKey="In-Progress" title="In-Progress Prediction">
                    <div className="availabilit-section">
                      <Table responsive="sm" className="UserListTable">
                        <thead>
                          <tr>
                          <th></th>
                            <th>Sr. No.</th>
                            <th>Statement Title</th>

                            <th className="email-section">
                              Prediction Start Date & Time(UTC)
                            </th>
                            <th>Prediction End Date & Time(UTC)</th>
                            <th>
                              Threshold{" "}
                              <OverlayTrigger
                                placement="top"
                                overlay={tooltipinfo}
                              >
                                <i
                                  class="fa fa-info-circle"
                                  aria-hidden="true"
                                ></i>
                              </OverlayTrigger>
                            </th>
                            <th>Prediction Type</th>
                            <th>Total Predictions</th>
                            <th>Amount Predicted</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {dataoptions && dataoptions?.length > 0 ? (
                            dataoptions?.map((data, index) => {
                              return (
                                <tr key={index}>
                                      {data.solo_type.trim()==""?
                                     <td onClick={()=>pin_unpin_prediction(data.isPin?false:true,data._id)}><i className={data.isPin?"fa fa-thumb-tack errors":"fa fa-thumb-tack"} aria-hidden="true"></i></td>:<td></td>}
                                  <td>{index + 1}</td>
                                  <td>
                                    <a
                                      href={
                                        "/transaction?contract=" +
                                        data?.contract_address?.toLowerCase()
                                      }
                                    >
                                      {data?.question ? (
                                        <>
                                          {data?.question?.length > 25
                                            ? data?.question?.slice(0, 25) +
                                            "..."
                                            : data?.question}
                                        </>
                                      ) : (
                                        <>-</>
                                      )}
                                    </a>
                                  </td>
                                  <td className="email-section">
                                    {data?.startDateTime ? (
                                      <>
                                        {moment
                                          .utc(data?.startDateTime)
                                          .format("DD-MM-YYYY hh:mm A")}
                                      </>
                                    ) : (
                                      <>-</>
                                    )}
                                  </td>
                                  <td>
                                    {data?.endDateTime ? (
                                      <>
                                        {moment
                                          .utc(data?.endDateTime)
                                          .format("DD-MM-YYYY hh:mm A")}
                                      </>
                                    ) : (
                                      <>-</>
                                    )}
                                  </td>

                                  <td>
                                    {data?.threshold_time ? (
                                      <>{parseMinutes(data?.threshold_time)}</>
                                    ) : (
                                      <>-</>
                                    )}
                                  </td>
                                  <td>
                                    {data?.prediction_type ? data?.prediction_type === "RISK-FREE" ? "Prize pool" : data?.prediction_type : ""}
                                    {data?.prediction_category !== "REGULAR" && (
                                      <>
                                        {data?.prediction_category === "POLL" && " ( POLL )"}
                                        {data?.prediction_category === "QUIZ" && " ( QUIZ )"}
                                        <span className="capitalized">
                                        &nbsp; {data?.solo_type}
                                        </span>
                                      </>
                                    )}
                                  </td>
                                  <td>{data?.predictionCount}</td>
                                  <td>
                                    {formatWithDecimals(data?.totalAmount, data?.decimal)} {data?.symbol}
                                  </td>
                                  <td>
                                    <div className="view-eye-area">
                                      <div
                                        className="d-flex align-items-center"
                                        onClick={() =>
                                          PredictionDetail(
                                            data?.contract_address
                                          )
                                        }
                                      >
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={tooltipview}
                                        >
                                          <div className="edit-pencil">
                                            <i
                                              class="fa fa-eye"
                                              style={{ cursor: "pointer" }}
                                              aria-hidden="true"
                                            ></i>
                                          </div>
                                        </OverlayTrigger>{" "}
                                      </div>
                                    </div>
                                  </td>

                                </tr>
                              );
                            })
                          ) : (
                            <tr className="no-dat-found-box">
                              <td colSpan={10}>
                                <img
                                  src={
                                    require("../../assets/images/no-data.svg")
                                      .default
                                  }
                                  alt="da"
                                />
                                <h3 className="text-center text-light">
                                  No data found
                                </h3>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                      <> </>
                    </div>

                    {dataoptions?.length > 0 && totalItems > 10 ? (
                      <Pagination
                        activePage={page + 1}
                        itemsCountPerPage={10}
                        totalItemsCount={totalItems}
                        pageRangeDisplayed={5}
                        onChange={(e) => handlePageChange(e)}
                        prevPageText={"Prev"}
                        nextPageText={"Next"}
                      />
                    ) : null}
                  </Tab>
                  <Tab eventKey="Past" title="Past Prediction">
                    <div className="availabilit-section">
                      <Table responsive="sm" className="UserListTable">
                        <thead>
                          <tr>
                          <th></th>
                            <th>Sr. No.</th>
                            <th>Statement Title</th>
                            <th className="email-section">
                              Prediction Start Date & Time(UTC)
                            </th>
                            <th>Prediction End Date & Time(UTC)</th>
                            <th>
                              Threshold{" "}
                              <OverlayTrigger
                                placement="top"
                                overlay={tooltipinfo}
                              >
                                <i
                                  class="fa fa-info-circle"
                                  aria-hidden="true"
                                ></i>
                              </OverlayTrigger>
                            </th>
                            <th>Prediction Type</th>
                            <th>Total Predictions</th>
                            <th>Amount Predicted</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {dataoptions && dataoptions?.length > 0 ? (
                            dataoptions?.map((data, index) => {
                              return (
                                <tr key={index }>
                                {data.solo_type.trim()==""?
                                     <td onClick={()=>pin_unpin_prediction(data.isPin?false:true,data._id)}><i className={data.isPin?"fa fa-thumb-tack errors":"fa fa-thumb-tack"} aria-hidden="true"></i></td>:<td></td>}
                                  <td>{index + 1}</td>
                                  <td>
                                    <a
                                      href={
                                        "/transaction?contract=" +
                                        data?.contract_address
                                      }
                                    >
                                      {data?.question ? (
                                        <>
                                          {data?.question?.length > 25
                                            ? data?.question?.slice(0, 25) +
                                            "..."
                                            : data?.question}
                                        </>
                                      ) : (
                                        <>-</>
                                      )}
                                    </a>
                                  </td>
                                  <td className="email-section">
                                    {data?.startDateTime ? (
                                      <>
                                        {moment
                                          .utc(data?.startDateTime)
                                          .format("DD-MM-YYYY hh:mm A")}
                                      </>
                                    ) : (
                                      <>-</>
                                    )}
                                  </td>
                                  <td>
                                    {data?.endDateTime ? (
                                      <>
                                        {moment
                                          .utc(data?.endDateTime)
                                          .format("DD-MM-YYYY hh:mm A")}
                                      </>
                                    ) : (
                                      <>-</>
                                    )}
                                  </td>


                                  <td>
                                    {data?.threshold_time ? (
                                      <>{parseMinutes(data?.threshold_time)}</>
                                    ) : (
                                      <>-</>
                                    )}
                                  </td>
                                  <td>
                                    {data?.prediction_type ? data?.prediction_type === "RISK-FREE" ? "Prize pool" : data?.prediction_type : ""}
                                    {data?.prediction_category !== "REGULAR" && (
                                      <>
                                        {data?.prediction_category === "POLL" && " ( POLL )"}
                                        {data?.prediction_category === "QUIZ" && " ( QUIZ )"}
                                        <span className="capitalized">
                                          &nbsp; {data?.solo_type}
                                        </span>
                                      </>
                                    )}
                                     
                                  </td>
                                  <td>{data?.predictionCount}</td>
                                  <td>
                                    {formatWithDecimals(data?.totalAmount, data?.decimal)} {data?.symbol}
                                  </td>
                                  <td>
                                    <div className="view-eye-area">

                                      <div
                                        className="d-flex align-items-center"
                                        onClick={() =>
                                          PredictionDetail(
                                            data?.contract_address
                                          )
                                        }
                                      >
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={tooltipview}
                                        >
                                          <div className="edit-pencil">
                                            <i
                                              class="fa fa-eye"
                                              style={{ cursor: "pointer" }}
                                              aria-hidden="true"
                                            ></i>
                                          </div>
                                        </OverlayTrigger>{" "}
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr className="no-dat-found-box">
                              <td colSpan={10}>
                                <img
                                  src={
                                    require("../../assets/images/no-data.svg")
                                      .default
                                  }
                                  alt="da"
                                />
                                <h3 className="text-center text-light">
                                  No data found
                                </h3>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                    {dataoptions?.length > 0 && totalItems > 10 ? (
                      <Pagination
                        activePage={page + 1}
                        itemsCountPerPage={10}
                        totalItemsCount={totalItems}
                        pageRangeDisplayed={5}
                        onChange={(e) => handlePageChange(e)}
                        prevPageText={"Prev"}
                        nextPageText={"Next"}
                      />
                    ) : null}
                  </Tab>
                </Tabs>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <Modal
        className="add-pediction-area"
        show={addData}
        onHide={handelCloseData}
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {predictionId ? "Update" : "Add"} Prediction
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-3">
          <Row md={12}>
            <Form>

              <Row md={12}>
                <div className="selling-option-top">
                  <h6>{predictionFields.prediction_type === "PAID" ? "Paid Predictions" : "Risk-free Predictions"}</h6>
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    disabled={predictionId}
                    checked={predictionFields.prediction_type === "PAID"}
                    onChange={handlePredictionTypeToggle}

                  />
                </div>
                <div className="paid-risk-options">
                  {console.log("prediction_category", predictionFields.prediction_category === "REGULAR")}
                  {['REGULAR', 'POLL', 'QUIZ'].map((type) => (
                    <div key={`prediction_category-${type}`} className="mb-3">
                      <Form.Check
                        type='radio'
                        id={`prediction_category-${type}`}
                        label={type}
                        name={type}
                        onChange={(e) => {
                          setpredictionFields((prevObj) => {
                            return {
                              ...prevObj,
                              prediction_category: type,
                            };
                          });
                        }}
                        checked={predictionFields.prediction_category === type ? true : false}
                      />
                    </div>
                  ))}
                </div>
              </Row>




              <Form.Group className="mb-2">
                <Form.Label>Image</Form.Label>

                <div className="common-image-area">
                  {!quesImageshow && (
                    <div className="upload-image-area">
                      <input type="file" onChange={(e) => onChangeImage(e)} />
                      <i class="fa fa-plus" aria-hidden="true"></i>{" "}
                    </div>
                  )}
                  {quesImageshow && (
                    <div className="upload-image-area-spacing">
                      <div className="upload-image-area-right">
                        <img
                          src={quesImageshow}
                          alt="iamge"
                          height={200}
                          width={200}
                        />
                        <i
                          class="fa fa-trash"
                          style={{ cursor: "pointer" }}
                          aria-hidden="true"
                          onClick={removeImage}
                        ></i>
                      </div>

                      <div className="choose-image-area">
                        <button
                          type="button"
                          style={{ cursor: "pointer" }}
                          variant="unset"
                        >
                          Change Image
                        </button>
                        <input type="file" onChange={(e) => onChangeImage(e)} />
                      </div>
                    </div>
                  )}
                </div>
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Label>
                  Statement Title <span>*</span>
                </Form.Label>
                <input
                  maxLength={100}
                  className="form-control "
                  type="textarea"
                  value={predictionFields.question}
                  onChange={(e) => {
                    setpredictionFields((prevObj) => {
                      return {
                        ...prevObj,
                        question: e.target.value?.trimStart(),
                      };
                    });
                  }}
                  onKeyDown={(e) => {
                    if (!e.target.value && e.code === "Space") {
                      e.preventDefault();
                    }
                  }}
                />
                <span className="text-danger">
                  {predictionFieldsErr.question}
                </span>
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Label>
                  Statement description <span>*</span>
                </Form.Label>
                <textarea
                  maxLength={1500}
                  className="form-control "
                  type="textarea"
                  value={predictionFields.description}
                  onChange={(e) => {
                    setpredictionFields((prevObj) => {
                      return {
                        ...prevObj,
                        description: e.target.value?.trimStart(),
                      };
                    });
                  }}
                  onKeyDown={(e) => {
                    if (!e.target.value && e.code === "Space") {
                      e.preventDefault();
                    }
                  }}
                />
                <span className="text-danger">
                  {predictionFieldsErr.description}
                </span>
              </Form.Group>

              {/* {console.log("predictionFields================", predictionFields)} */}


              {predictionFields?.prediction_category != "REGULAR" && <>
                {predictionFields?.answerOption?.map((item, index) => {
                  return (<Form.Group className="mb-2 option-area">
                    <Form.Label>
                      Option {index + 1} <span>*</span>
                    </Form.Label>
                    <div className="third-option">
                      <input
                        className="form-control "
                        type="text"
                        value={item}
                        disabled={predictionId}
                        onChange={(e) => {
                          let predictionFieldsTemp = { ...predictionFields }
                          let temp = [...predictionFieldsTemp.answerOption];
                          temp[index] = e.target.value?.trimStart();
                          predictionFieldsTemp.answerOption = temp;
                          setpredictionFields(predictionFieldsTemp);
                        }}
                      />
                      {predictionFields.answerOption.length > 2 && <i class="fa fa-trash" style={{ cursor: "pointer" }} aria-hidden="true" onClick={() => removeAnswerOption(index)}></i>}
                    </div>
                    {(!item || item?.trim()) && answerOptionError[index] ? <span className="text-danger">{answerOptionError[index]}</span> : ""}

                  </Form.Group>)
                })}
                {predictionFields?.answerOption?.length >= 5 ? "" : <p className="add-option" ><i class="fa fa-plus" aria-hidden="true" onClick={() => addAnswerOption()}></i></p>}
              </>}



              <Form.Group className="mb-2">
                <Form.Label>
                  Prediction Duration <span>*</span>
                </Form.Label>
                <div className="dropdown-icon-area">
                  <select
                    className="form-control"
                    onChange={(e) => {
                      setpredictionFields((prevObj) => {
                        if (e.target.value !== predictionFields.duration) {
                          return {
                            ...prevObj,
                            duration: e.target.value,
                            threshold_time: "",
                          };
                        } else {
                          return {
                            ...prevObj,
                            duration: e.target.value,
                          };
                        }
                      });
                      endDateDuration(
                        e.target.value,
                        predictionFields.startDateTime
                      );
                    }}
                    value={predictionFields.duration}
                  >
                    <option value={"HOUR"}>One HOUR</option>
                    <option value={"DAY"}>One Day</option>
                    <option value={"WEEK"}>One Week</option>
                    <option value={"MONTH"}>One Month</option>
                  </select>
                  <i class="fa fa-angle-down" aria-hidden="true"></i>
                </div>

                <span className="text-danger">
                  {predictionFieldsErr.duration}
                </span>
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Label>
                  Prediction Start Date & Time <span>*</span>
                </Form.Label>
                <input
                  className="form-control "
                  id="dateTime"
                  value={predictionFields.startDateTime}
                  min={getmintime()}
                  onKeyDown={(e) => e.preventDefault()}
                  type="datetime-local"
                  onChange={(e) => OnchangeFromtime(e.target.value)}
                  autoClose={true}
                />
                <p className="utc-time">
                  <span className="sub-head">
                    Betting Start Date & Time(UTC):
                  </span>
                  {startDate}
                </p>
                <span className="text-danger">
                  {predictionFieldsErr.start_time}
                </span>
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Label>
                  Prediction End Date & Time <span>*</span>
                </Form.Label>
                <div className="dropdown-icon-area">
                  <input
                    className="form-control"
                    disabled
                    type="text"
                    onChange={(e) => OnchangeTotime(e.target.value)}
                    value={predictionFields.endDateTime}
                  />
                  <i class="fa fa-calendar-o" aria-hidden="true"></i>
                </div>
                <p className="utc-time">
                  <span className="sub-head">
                    Prediction End Date & Time(UTC):{endDate}
                  </span>
                </p>

                <span className="text-danger">
                  {predictionFieldsErr.end_time}
                </span>
              </Form.Group>

              <Form.Group className="mb-2">
                <Form.Label>
                  Threshold Time{" "}
                  <OverlayTrigger placement="top" overlay={tooltipinfo}>
                    <i class="fa fa-info-circle" aria-hidden="true"></i>
                  </OverlayTrigger>{" "}
                  <span>*</span>
                </Form.Label>
                <div className="dropdown-icon-area">
                  <select
                    className="form-control "
                    onChange={(e) => {
                      setpredictionFields((prevObj) => {
                        return {
                          ...prevObj,
                          threshold_time: e.target.value,
                        };
                      });
                    }}
                    value={predictionFields.threshold_time}
                  >
                    <option value="">Please Select</option>

                    {thresholdTimeFram.map((threshold, i) => (
                      <>
                        <option key={i} value={threshold.value}>
                          {threshold.label}
                        </option>
                      </>
                    ))}
                  </select>
                  <i class="fa fa-angle-down" aria-hidden="true"></i>
                </div>

                <span className="text-danger">
                  {predictionFieldsErr.threshold_time}
                </span>
              </Form.Group>



              <Form.Group className="mb-2">
                <Form.Label>
                  Data Provider{" "}
                  <span>*</span>
                </Form.Label>
                <div className="dropdown-icon-area">
                  <select
                    className="form-control "
                    onChange={(e) => {
                      setpredictionFields((prevObj) => {
                        return {
                          ...prevObj,
                          data_provider: e.target.value,
                        };
                      });
                    }}
                    value={predictionFields.data_provider}
                  >

                    {dataProviders.map((value, i) => (
                      <>
                        <option key={i} value={value}>
                          {value}
                        </option>
                      </>
                    ))}
                  </select>
                  <i class="fa fa-angle-down" aria-hidden="true"></i>
                </div>
              </Form.Group>


              <Form.Group className="mb-2">
                <Form.Label>
                  Token Address <span>*</span>
                </Form.Label>
                <input
                  maxLength={100}
                  className="form-control"
                  type="text"
                  value={predictionFields.prediction_type === 'PAID' ? tokenAddress : predictionFields.token_address}
                  disabled={predictionId || predictionFields.prediction_type === 'PAID'}
                  onChange={handleChange}
                  onKeyDown={(e) => {
                    if (!e.target.value && e.code === 'Space') {
                      e.preventDefault();
                    }
                  }}
                  onBlur={handleBlur}
                />
                <span className="text-danger">{predictionFieldsErr.token_address ? predictionFieldsErr.token_address : ''}</span>
              </Form.Group>
              {predictionFields?.prediction_type ==
                "RISK-FREE" && <Form.Group className="mb-2">
                  <Form.Label>
                    Chain Name <span>*</span>
                  </Form.Label>
                  <input
                    maxLength={100}
                    className="form-control"
                    disabled={predictionId}
                    type="text"
                    value={predictionFields.chain_name}
                    onChange={(e) => {
                      setpredictionFields((prevObj) => ({
                        ...prevObj,
                        chain_name: e.target.value,
                      }));
                    }}
                    onKeyDown={(e) => {
                      if (!e.target.value && e.code === "Space") {
                        e.preventDefault();
                      }
                    }}
                  />
                  <span className="text-danger">{predictionFieldsErr.chain_name}</span>
                </Form.Group>}


              {predictionFields?.prediction_type ==
                "RISK-FREE" && <Form.Group className="mb-2">
                  <Form.Label>
                    Symbol <span>*</span>
                  </Form.Label>
                  <input
                    maxLength={100}
                    className="form-control"
                    type="text"
                    disabled={predictionId && true}
                    value={predictionFields.symbol}
                    onChange={(e) => {
                      setpredictionFields((prevObj) => ({
                        ...prevObj,
                        symbol: e.target.value,
                      }));
                    }}
                    onKeyDown={(e) => {
                      if (!e.target.value && e.code === "Space") {
                        e.preventDefault();
                      }
                    }}
                  />
                  <span className="text-danger">{predictionFieldsErr.symbol}</span>
                </Form.Group>}

              {predictionFields?.prediction_type ==
                "RISK-FREE" && <Form.Group className="mb-2">
                  <Form.Label>
                    Decimal <span>*</span>
                  </Form.Label>
                  <input
                    maxLength={100}
                    className="form-control"
                    type="text"
                    disabled={true}
                    value={predictionFields.decimal}
                  />
                </Form.Group>}


              {predictionFields?.prediction_type == "RISK-FREE" && <Form.Group className="mb-2">
                <Form.Label>
                  Amount <span>*</span>
                </Form.Label>
                <input
                  maxLength={10}
                  className="form-control"
                  type="text"
                  value={predictionId ? formatWithDecimals(predictionFields.amount, predictionFields?.decimal) : predictionFields.amount}
                  disabled={predictionId}
                  onChange={(e) => {
                    setisWalletBalance(true)
                    if (predictionFields.token_address) {
                      setpredictionFields((prevObj) => {
                        return {
                          ...prevObj,
                          amount: prevObj.token_address ? e.target.value : "",
                        };
                      });
                    } else {
                      setpredictionFieldsErr((obj) => {
                        return {
                          ...obj,
                          token_address: "Please enter Token Address",
                        };
                      });
                    }

                  }}
                  onKeyDown={(e) => KeyDownPhone.includes(e.key) && e.preventDefault()}
                  onBlur={(e) => {
                    if (predictionFields?.prediction_type == "RISK-FREE" && predictionFields.amount !== "") {
                      getWalletBalance(predictionFields?.token_address)
                    }
                  }}
                />
                <span className="text-danger">
                  {predictionFieldsErr.amount || isWalletBalance == true ? predictionFieldsErr.amount : ""}
                </span>
              </Form.Group>}


              <div className="commission-area">
                <h6>Who can get commission % on Prediction </h6>
                <div className="comission-outer-area" ref={commissionDataRef}>
                  <div className="comission-inner-area commission-label-area">
                    <Form.Group className="comission-wallet-address">
                      <Form.Label>Wallet Address</Form.Label>
                    </Form.Group>
                    <Form.Group className="percentage-area">
                      <Form.Label>Percentage</Form.Label>
                    </Form.Group>
                  </div>

                  {predictionFields.commissionData?.map((commission, index) => (
                    <div key={index} className="comission-inner-area">
                      <Col md={6}>
                        <Form.Group className="comission-wallet-address">
                          <Form.Control
                            type="text"
                            placeholder="Enter Wallet Address"
                            value={commission?.walletAddress}
                            onChange={(e) =>
                              handleWalletAddressChange(index, e.target.value)
                            }
                          />
                          {!predictionFields?.commissionData[index]
                            ?.walletAddress &&
                            predictionFieldsErr.commissionData[index]
                              ?.walletAddress && (
                              <span className="text-danger">
                                {
                                  predictionFieldsErr.commissionData[index]
                                    ?.walletAddress
                                }
                              </span>
                            )}
                        </Form.Group>
                      </Col>
                      <Col md={4}>
                        <Form.Group className="percentage-area">
                          <Form.Control
                            type="Number"
                            min={0}
                            max={100}
                            placeholder="Enter %"
                            value={commission?.percentage}
                            onChange={(e) =>
                              handlePercentageChange(index, e.target.value)
                            }
                          />
                          {!predictionFields.commissionData[index]
                            ?.percentage &&
                            predictionFieldsErr.commissionData[index]
                              ?.percentage && (
                              <span className="text-danger">
                                {
                                  predictionFieldsErr.commissionData[index]
                                    ?.percentage
                                }
                              </span>
                            )}
                        </Form.Group>
                      </Col>
                      <Col md={2}>
                        {predictionFields.commissionData?.length != 1 && (
                          <i
                            onClick={() => handleRemoveCommissionRow(index)}
                            class="fa fa-trash"
                            aria-hidden="true"
                          ></i>
                        )}
                        {predictionFields.commissionData?.length - 1 ==
                          index && (
                            <Button
                              type="button"
                              variant="unset"
                              onClick={() => handleAddCommissionRow(index)}
                            >
                              <i className="fa fa-plus" aria-hidden="true"></i>
                            </Button>
                          )}
                      </Col>
                    </div>
                  ))}
                </div>
                {commissionPercentage != 100 &&
                  predictionFieldsErr.commissionPercentage && (
                    <span className="text-danger">
                      {predictionFieldsErr.commissionPercentage}
                    </span>
                  )}
                <span className="text-danger">{notvalidComissionAddress}</span>
              </div>

              <Form.Group className="mb-3">
                <Form.Label>Who can bet</Form.Label>
                <div className="who-cant-bet-area">
                  <Form.Check
                    type="checkbox"
                    id="canBetCheckbox"
                    label="All"
                    checked={!predictionFields.canBet}
                    onChange={(e) =>
                      setpredictionFields({
                        ...predictionFields,
                        canBet: !e.target.checked,
                        specificWallets: [""],
                      })
                    }
                  />
                  <Form.Check
                    type="checkbox"
                    id="specificWalletCheckbox"
                    label="Specific Wallet"
                    checked={predictionFields.canBet}
                    onChange={(e) =>
                      setpredictionFields({
                        ...predictionFields,
                        canBet: e.target.checked,
                        specificWallets: specificWalletAddrerss,
                      })
                    }
                  />
                </div>
              </Form.Group>

              {predictionFields.canBet && (
                <div className="comission-outer-area" ref={specificWalletsRef}>
                  <Form.Group className="percentage-area">
                    <Form.Label>Wallet Address</Form.Label>
                  </Form.Group>
                  {predictionFields.specificWallets?.length > 0 &&
                    predictionFields.specificWallets?.map((wallet, index) => (
                      <div
                        key={index}
                        className="comission-inner-area wallet-address-bottom"
                      >
                        <Col md={10}>
                          <Form.Group className="comission-wallet-address">
                            <Form.Control
                              type="text"
                              placeholder="Enter Wallet Address"
                              value={wallet}
                              onChange={(e) =>
                                handleWalletChange(index, e.target.value)
                              }
                            />
                            {!predictionFields.specificWallets[index] &&
                              predictionFieldsErr.specificWallets[index] && (
                                <span className="text-danger">
                                  {predictionFieldsErr.specificWallets[index]}
                                </span>
                              )}
                          </Form.Group>
                        </Col>
                        <Col md={2}>
                          {predictionFields.specificWallets?.length != 1 && (
                            <i
                              class="fa fa-trash"
                              aria-hidden="true"
                              onClick={() => handleRemoveWallet(index)}
                            ></i>
                          )}
                          {predictionFields.specificWallets?.length - 1 ==
                            index && (
                              <Button
                                type="button"
                                variant="unset"
                                onClick={() => handleAddWallet(index)}
                              >
                                <i class="fa fa-plus" aria-hidden="true"></i>
                              </Button>
                            )}
                        </Col>
                      </div>
                    ))}
                  <span className="text-danger">{notvalidAddress}</span>
                </div>
              )}


              {predictionFields?.prediction_type == "RISK-FREE" && <Form.Group className="mb-3">
                <Form.Label>Predictions Access Setting <span>*</span></Form.Label>
                <div className="paid-risk-options">
                  {accessSettingOptions.map((option) => (
                    <div key={`prediction_access_settings-${option.value}`}>
                      <Form.Check
                        type='radio'
                        id={`prediction_access_settings-${option.value}`}
                        label={option.label}
                        name={option.value}
                        disabled={predictionId}
                        onChange={(e) => {
                          accessSettingOptionsChanged(option.value)
                        }}
                        checked={predictionFields.accessSetting === option.value ? true : false}
                      />
                    </div>
                  ))}
                </div>
              </Form.Group>
              }
              {predictionFields?.prediction_type == "RISK-FREE" && (predictionFields?.accessSetting == 3 || predictionFields?.accessSetting == 5) &&
                <Form.Group className="mb-2">
                  <Form.Label>
                    NFT Contract Address <span>*</span>
                  </Form.Label>
                  <input
                    maxLength={100}
                    className="form-control"
                    type="text"
                    disabled={predictionId}
                    value={predictionFields.nftContractAddress}
                    onChange={(e) => {
                      setpredictionFields((prevObj) => {
                        return {
                          ...prevObj,
                          nftContractAddress: e.target.value?.trimStart(),
                        };
                      });
                    }}
                    onKeyDown={(e) => {
                      if (!e.target.value && e.code === "Space") {
                        e.preventDefault();
                      }
                    }}
                    onBlur={handlenftContractAddressBlur}

                  />
                  <span>
                    {predictionFields?.nftContractAddressName ? predictionFields?.nftContractAddressName : ""}
                  </span>
                  {(!predictionFields.nftContractAddress || !predictionFields.nftContractAddress.trim() || predictionFieldsErr?.nftContractAddressError) && predictionFieldsErr?.nftContractAddress && <span className="text-danger">
                    {predictionFieldsErr?.nftContractAddress}
                  </span>}

                </Form.Group>
              }
              {predictionFields?.prediction_type == "RISK-FREE" && (predictionFields?.accessSetting == 4 || predictionFields?.accessSetting == 6) &&
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-2">
                      <Form.Label>
                        Token Address <span>*</span>
                      </Form.Label>
                      <input
                        maxLength={100}
                        className="form-control "
                        type="text"
                        disabled={predictionId}
                        value={predictionFields.accessSettingTokenAddress}
                        onChange={(e) => {
                          setpredictionFields((prevObj) => {
                            return {
                              ...prevObj,
                              accessSettingTokenAddress: e.target.value?.trimStart(),
                              accessSettingTokenAmount: ""
                            };
                          });
                        }}
                        onKeyDown={(e) => {
                          if (!e.target.value && e.code === "Space") {
                            e.preventDefault();
                          }
                        }}
                        onBlur={handleAccessSettingTokenBlur}

                      />
                      {(!predictionFields.accessSettingTokenAddress || !predictionFields.accessSettingTokenAddress.trim() || predictionFieldsErr?.accessSettingTokenAddressError) && predictionFieldsErr?.accessSettingTokenAddress && <span className="text-danger">
                        {predictionFieldsErr?.accessSettingTokenAddress}
                      </span>}

                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-2">
                      <Form.Label>
                        Amount <span>*</span>
                      </Form.Label>
                      <input
                        maxLength={100}
                        className="form-control "
                        type="text"
                        disabled={predictionId}
                        value={predictionFields.accessSettingTokenAmount}
                        onChange={handleAccessSettingTokenAmountChange}
                        onKeyDown={(e) => {
                          if (!e.target.value && e.code === "Space") {
                            e.preventDefault();
                          }
                        }}

                      />
                      {(!predictionFields.accessSettingTokenAmount || !predictionFields.accessSettingTokenAmount.trim() || (predictionFieldsErr?.accessSettingTokenAmountError && (parseFloat(predictionFields?.accessSettingTokenAmount) > accessSettingTokenAmountRef.current))) && predictionFieldsErr?.accessSettingTokenAmount && <span className="text-danger">
                        {predictionFieldsErr?.accessSettingTokenAmount}
                      </span>}

                    </Form.Group>
                  </Col>
                </Row>
              }
              {predictionFields?.prediction_type == "RISK-FREE" &&
                <Form.Group className="mb-2">
                  <Form.Label>
                    Disclaimer<span>*</span>
                  </Form.Label>
                  <textarea
                    maxLength={1500}
                    className="form-control "
                    type="textarea"
                    value={predictionFields.disclaimer}
                    onChange={(e) => {
                      setpredictionFields((prevObj) => {
                        return {
                          ...prevObj,
                          disclaimer: e.target.value?.trimStart(),
                        };
                      });
                    }}
                    onKeyDown={(e) => {
                      if (!e.target.value && e.code === "Space") {
                        e.preventDefault();
                      }
                    }}
                  />
                  {(!predictionFields?.disclaimer) && predictionFieldsErr?.disclaimer && <span className="text-danger">
                    {predictionFieldsErr?.disclaimer}
                  </span>}

                </Form.Group>
              }

            </Form>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => addprediction()}>Save</Button>
          <Button onClick={handelCloseData} className="close-btn-bottom">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
