import { Col, Container, Form, OverlayTrigger,
    Row,
    Table,
    Tooltip,} from "react-bootstrap";
import Select from "react-select";
import { apiService } from "../../service/api.service";
import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import { CopyToClipboard } from "react-copy-to-clipboard";
import swal from "sweetalert";
import moment from "moment";
import { ThreeDotSpinner } from "../loader";
import {
    baseUrl,
    dateFormate,
    formatBalance,
    formatWithDecimals,
    transactionurl,
  
  } from "../../config/config";
const BetsWon = () => {
    const [loader, setLoader] = useState(false)
    const [totalItems, setTotalItems] = useState();
    const [page, setPage] = useState(0);
    const [type, setType] = useState("All");
   
    const [copied, setcopied] = useState(false);
    const [betsList, setbetsList] = useState([])
    const [date, setDate] = useState(new Date());
    const formatDate = (newdate) => {
        console.log("======date==",newdate)
       
            const year = newdate.getFullYear();
            const month = String(newdate.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
            const day = String(newdate.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
 
      
      };
    
    const options = [
        { value: "All", label: "All" },
        { value: "solo-predictions", label: "Solo Predictions" },
        { value: "group", label: "Group Predictions" },
        { value: "multi-choice-predictions", label: "Multichoice Predictions" },
    ];
    const formatAddress = (addr) => {
        return `${addr.substring(0, 4)}...${addr.substring(addr.length - 4)}`;
      };
    useEffect(() => {
        onGetBets(0, type,date)
    }, [])
    const copytooltip = (
        <Tooltip id="tooltip">{copied ? "Copied" : "Copy"}</Tooltip>
      );
    const onGetBets = async (page, types,dates) => {
        console.log("===sss==",dates)
        setLoader(true);
        try {
            let params = {
                page :page + 1,
                soloType: types ? types : "All",
                date:new Date(dates).toISOString()
            }
        
            const response = await apiService.get_winning_predictions(params);
         
            if (response.status == 200) {
                setbetsList(response.data.data)
                setTotalItems(response?.data?.pagination?.totalItems);
                setLoader(false);
            }
        } catch (error) {
            setLoader(false);
            if (error?.response?.status == 401) {
                swal({ text: "Unauthorized", button: "OK" }).then(() => {
                    localStorage.clear();
                    window.location.href = "/";
                });
            } else {
            }
        }
    }
    function handlePageChange(pageNumber) {
        if (page != pageNumber - 1) {
            setPage(pageNumber - 1);
            onGetBets(pageNumber - 1,type)
        }
    }

    const handleDateChange = (event) => {
        const dates = event.target.value; // This is a string in 'YYYY-MM-DD' format
        const newDate = new Date(dates); // Convert string to Date object
        const formattedDate = formatDate(newDate); // Format the date
        setDate(newDate); // Store the Date object in the state
        onGetBets(0, type, formattedDate); // Pass the formatted date to onGetBets
      };
      const handleTypeChange = (typeData) => {
        const types =typeData
        setType(types);
        onGetBets(0, types,date)
      };
    
    return (
        <>
            <div className="dashboard-main-area">
                {loader == true ? <ThreeDotSpinner /> : ""}
                <Container fluid>
                    <div className="dashboard-area-heading management-heading">
                        <Row className="row justify-content-center align-items-center mb-3">
                            <Col md={12} lg={11}>
                                <div className="heading-top-area">
                                    <div className="d-flex ">
                                        <h2 className="align-self-center m-0">
                                            Bets Won
                                        </h2>

                                    </div>
                                    <div className="heading-top-area-right bets-won-filter">
                                        <div className="filter-top-area me-2">
                                            <div className="date-picker">
                                                <label>Date:</label>
                                               <input type="date" value={formatDate(date)} onChange={handleDateChange}/>
                                            </div>
                                            <Form.Group
                                                className=""
                                                controlId="exampleForm.ControlInput1"
                                            >
                                                <Form.Label className="mb-0">Filter:</Form.Label>
                                                <Select
                                                    options={options}
                                                    placeholder="All"
                                                 onChange={(e) => handleTypeChange(e.value)}
                                                />
                                            </Form.Group>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="pb-4 justify-content-center">
                            <Col md={12} lg={11}>
                                <div className="audit-request-box">
                                    <Table responsive="sm" className="UserListTable">
                                        <thead>
                                            <tr>
                                                <th>Sr. No.</th>
                                                <th>Prediction Name</th>
                                                <th>Wallet Address</th>
                                                <th>Prediction Type</th>
                                                <th>Points </th>
                                                <th>Amount </th>
                                                <th>Date</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                        {betsList && betsList?.length > 0 ? (
                                                betsList?.map((data, index) => {
                                                  let  access_setting =  data.predictionDetails.access_setting
                                                  let type = "Group"
                                                  if(access_setting == 7){
                                                    type="SOLO"
                                                  }else if(access_setting == 8 || access_setting == 9 || access_setting == 10){
                                                    type="MultiChoice"
                                                  }

                                                    return (

                                            <tr>
                                                <td>{index+1}</td>
                                                <td>
                                                    <a 
                                                        target="_blank" 
                                                        href={`prediction-detail/${data?.predictionDetails?.contract_address}`}
                                                    >
                                                        {data?.predictionDetails?.question}
                                                    </a>
                                                </td>
                                                <td> {formatAddress(data.user)} <OverlayTrigger
                                  placement="top"
                                  overlay={copytooltip}
                                >
                                  <CopyToClipboard
                                    text={data.user}
                                    onCopy={() => setcopied(true)}
                                  >
                                    <i
                                      class="fa fa-clipboard"
                                      style={{ cursor: "pointer" }}
                                      aria-hidden="true"
                                    ></i>
                                  </CopyToClipboard>
                                </OverlayTrigger></td>
                                                <td>{type} </td>
                                                <td>{data.points}</td>
                                                <td>{ formatWithDecimals(data?.reward_amount,data?.predictionDetails?.decimal)}  {data?.predictionDetails?.symbol}</td>
                                                <td>{data.predictionDetails.resultTime ? moment(data.predictionDetails.resultTime).format(dateFormate) : "N/A"}</td>
                                            </tr> );
                                                })
                                            ) : (
                                                <tr className="no-dat-found-box">
                                                    <td colSpan={10}>
                                                        <img
                                                            src={
                                                                require("../../assets/images/no-data.svg")
                                                                    .default
                                                            }
                                                            alt="da"
                                                        />
                                                        <h3 className="text-center text-light">
                                                            No data found
                                                        </h3>
                                                    </td>
                                                </tr>
                                            )}

                                      </tbody>
                                    </Table>

                                    {betsList.length > 0 && totalItems > 10 ? (
                                    <Pagination
                                        activePage={page + 1}
                                        itemsCountPerPage={10}
                                        totalItemsCount={totalItems}
                                        pageRangeDisplayed={5}
                                        onChange={(e) => handlePageChange(e)}
                                        prevPageText={"Prev"}
                                        nextPageText={"Next"}
                                    />
                                ) : null}
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </>
    )
};
export default BetsWon;